import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMenuList } from "../../../jsx/layouts/nav/Menu";
import Logoutbtn from "../../../jsx/layouts/nav/Logoutbtn";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDesks } from "../../Redux/slices/admin.slice";
import ReactSelect from "react-select";
import { setAllContracts, setFilters } from "../../Redux/slices/contract.slice";
import axios from "axios";
import config from "../../../config/config";
import { setDeskSidebar } from "../../Redux/slices/actions.slice";
import sifflet from "../../../son/sonfifa.wav";

const SideBar = () => {
  const adminDesks = useSelector(getAdminDesks);

  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState(0);
  const [activeMenuItemIndexSub, setActiveMenuItemIndexSub] = useState(0);
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(null);
  const [selectedLinkIndexSub, setSelectedLinkIndexSub] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [goodIndextop, setGoodIndextop] = useState(null);
  const [goodIndexBot, setGoodIndexBot] = useState(null);

  const [showDetailsDesk, setShowDetailsDesk] = useState(false);
  const [deskSelected, setDeskSelected] = useState(null);
  const [showDetailsDeskChildren, setShowDetailsDeskChildren] = useState(false);

  const menuItems = useMenuList();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const desksObjects = [];
  const TilteObjects = [];

  // Parcourir menuItems pour trouver les éléments avec le titre "Desks"
  menuItems?.forEach((item) => {
    if (item.title === "Desks") {
      desksObjects.push(item);
    }
  });

  menuItems?.forEach((item) => {
    if (item.title !== "Desks") {
      TilteObjects.push(item);
    }
  });

  const desksUser = adminDesks?.map((desk) => ({
    value: desk,
    label: desk,
  }));

  const handlebrandDesk = (selectedOption) => {
    setShowDetailsDesk(true);
    dispatch(setDeskSidebar(selectedOption.value));
    setDeskSelected(selectedOption.value);
    setGoodIndextop(0);
    setGoodIndexBot(null);
    navigate(`/m_stats/${selectedOption.value}`);
    setSelectedLinkIndex(null)
    setActiveMenuItemIndexSub(null)
    
  };

  const handleClick = () => {
    setShowDetails(!showDetails); // Toggle la visibilité
  };

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const GetAllContrats = (desk) => {
    const obj = {
      filters: {
        desk: desk,
        is_active: 1,
      },
      filters_date: {},
      sorts: {},
      limit: "",
    };
    dispatch(
      setFilters({
        filters: {
          desk: desk,
        },
        filters_date: {},
        sorts: {},
        limit: "",
      })
    );
    axios
      .post(`${config.apiAdmin}/sadmin/contracts/search_and_sort`, obj, Header)
      .then((response) => {
        dispatch(setAllContracts(response.data.data.results));
        // setLoaderStart(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // setLoaderStart(false);
      });
  };

  const resetSelect = () => {
    setDeskSelected(null);
  };

  const desksItem = menuItems.find((item) => item.title === "Desks");

  const playWhistleSound = () => {
    const audio = new Audio(sifflet);
    audio.volume = 0.025; // Réduire le volume à 50%
    audio.play();
  };

  return (
    <div className={`deznav border-right`}>
      <div className="deznav-scroll d-flex flex-column">
        {TilteObjects?.map((item, itemIndex) => {
          if (item?.title === "Configurations" && adminDesks.length > 1) {
            return;
          }
          if ( item?.title === "Leads" && ["proddub", "prod"].includes(process.env.REACT_APP_CONFIG)) {
            return null;
          }
          return (
            <div className="d-flex flex-column" key={itemIndex}>
              <Link
                onClick={() => {
                  if (item?.title === "Contrats") {
                    // setDeskSelected(null)
                    GetAllContrats(adminDesks);
                  }
                  setActiveMenuItemIndex(
                    activeMenuItemIndex === itemIndex ? null : itemIndex
                  );
                  setShowDetailsDesk(false);
                  setShowDetailsDeskChildren(false);
                  setDeskSelected(null);
                  setGoodIndextop(null);
                  setGoodIndexBot(null);
                  setSelectedLinkIndex(null);
                }}
                to={item?.to}
                className={`d-flex align-items-center mx-4 my-2 btn-sidebar-title rounded-3 ${
                  itemIndex === activeMenuItemIndex ? "bg-light border" : ""
                }`}
              >
                <div className="menu-icon border rounded-5 p-2 bg-light">
                  {item?.iconStyle}
                </div>
                <div className="sidebar-title ms-3">{item?.title}</div>
              </Link>
              {item?.content && activeMenuItemIndex === itemIndex
                ? item?.content.map((subItem, subIndex) => {
                    const titlesToExclude = adminDesks.length >= 1 ? [] : [];
                    if (titlesToExclude.includes(subItem?.title)) {
                      return null;
                    }
                    return (
                      <Link
                        to={subItem?.to  || "#"}
                        key={subIndex}
                        className={`sidebar-config my-1 py-1 col-6 ps-3 ${
                          selectedLinkIndex === subIndex ? "selected" : ""
                        }`}
                        onClick={() => {
                          if (
                            subItem?.title === "Journalière" ||
                            subItem?.title === "Mensuelle"
                          ) {
                            playWhistleSound();
                          }
                          setSelectedLinkIndex(subIndex);
                        }}
                      >
                        <div>{subItem?.title}</div>
                      </Link>
                    );
                  })
                : null}
            </div>
          );
        })}
        {adminDesks?.length > 1 && (
          <div>
            <div className="fs-20 text-black py-2" onClick={handleClick}>
              <div className="d-flex align-items-center mx-4">
                <Link
                  to={"m"}
                  className="menu-icon border rounded-5 p-2 bg-light"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowDetailsDesk(false);
                    setShowDetailsDeskChildren(false);
                    resetSelect();
                    setGoodIndextop(null);
                    setGoodIndexBot(null);
                    setActiveMenuItemIndex(0);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className="svg-main-icon"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z"
                        fill="#000000"
                      />
                      <path
                        d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                </Link>
                <div className="sidebar-title ms-3"></div>
                <ReactSelect
                  options={desksUser}
                  onChange={handlebrandDesk}
                  // onClick={set}
                  className="fs-18"
                  placeholder="Desk"
                  value={deskSelected ? desksUser?.label : null}
                />
              </div>
            </div>
            {showDetailsDesk && (
              <div>
                {desksItem?.content?.map((contentItem, index) => {
                  // console.log(contentItem)
                  return(
                  <div className="d-flex flex-column" key={index}>
                    <Link
                      to={contentItem?.to}
                      key={index}
                      onClick={() => {
                        // if (contentItem?.title === "Performance") {
                        //   // playWhistleSound();
                        //   return
                        // }
                        if (contentItem?.title === "Contrats") {
                          GetAllContrats(deskSelected);
                        }
                        setActiveMenuItemIndexSub(
                          activeMenuItemIndexSub === index ? null : index
                        );
                        setShowDetailsDeskChildren(false);
                        setGoodIndextop(index);
                        setGoodIndexBot(null);
                        setSelectedLinkIndexSub(null);
                        setSelectedLinkIndex(null);
                      }}
                      className={`d-flex align-items-center my-2 col-10 m-auto btn-sidebar-title rounded-3 ${
                        index === activeMenuItemIndexSub ? "bg-light border" : ""
                      }`}
                    >
                      <div className="menu-icon border rounded-5 p-2 bg-light">
                        {contentItem?.iconStyle}
                      </div>
                      <div className="sidebar-title-sub ms-3">
                        {contentItem.title}
                      </div>
                    </Link>
                    {contentItem?.content && activeMenuItemIndexSub === index
                      ? contentItem?.content.map((subItem, subIndex) => {
                          const titlesToExclude =
                            adminDesks.length >= 1 ? [] : [];
                          if (titlesToExclude.includes(subItem?.title)) {
                            return null;
                          }
                          return (
                            <Link
                              to={subItem?.to + "/" + deskSelected || "#"}
                              key={subIndex}
                              className={`sidebar-config my-1 py-1 col-6 ps-3 ${
                                selectedLinkIndexSub === subIndex ? "selected" : ""
                              }`}
                              onClick={() => {
                                if (
                                  subItem?.title === "Journalière" ||
                                  subItem?.title === "Mensuelle"
                                ) {
                                  playWhistleSound();
                                }
                                setSelectedLinkIndexSub(subIndex);
                              }}
                            >
                              <div>{subItem?.title}</div>
                            </Link>
                          );
                        })
                      : null}
                  </div>
                )})}
                {desksItem?.children?.map((contentItemCh, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setShowDetailsDeskChildren(!showDetailsDeskChildren);
                    }}
                    className={`d-flex align-items-center my-2 col-10 m-auto btn-sidebar-title rounded-3}`}
                  >
                    <div className="menu-icon border rounded-5 p-2 bg-light">
                      {contentItemCh?.iconStyle}
                    </div>
                    <div className="sidebar-title-sub ms-3">
                      {contentItemCh?.title?.slice(0, 14)}
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-caret-down-fill ms-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                    <div></div>
                  </div>
                ))}
              </div>
            )}
            {showDetailsDeskChildren && (
              <div className="d-flex align-items-center">
                {desksItem?.children?.map((child, index) => (
                  <div key={index} className="col-10 m-auto">
                    {child.content?.map((contentItem, contentIndex) => (
                      <Link
                        key={contentIndex}
                        to={contentItem?.to + "/" + deskSelected || "#"}
                        className={`d-flex align-items-center btn-sidebar-title-hover rounded-3 p-2 ${
                          contentIndex === goodIndexBot ? "bg-light " : ""
                        }`}
                        onClick={() => {
                          setGoodIndexBot(contentIndex);
                          setGoodIndextop(null);
                          setSelectedLinkIndex(null);
                          setSelectedLinkIndexSub(null);
                        }}
                      >
                        <div
                          key={contentIndex}
                          className={`sidebar-config ms-5 `}
                        >
                          {contentItem?.title}
                        </div>
                      </Link>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="switch-btn mt-auto">
          <Logoutbtn />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
