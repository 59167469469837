import React, { useState } from "react";
import { Form, Button, Table, Modal } from "react-bootstrap";

const History = () => {
  const dataToShow = [
    {
      Name: "Genettaz Cecile",
      Email: "cecile.genettaz@example.com",
      Status: "New",
      Phone: "+33******91",
      "Phone 2": "",
      "Phone 3": "",
      Verified: "NO",
      "Dealer ID": "3029713",
      Referral: "livfsreven",
      Country: "France",
      City: "Paris",
      State: "",
      "Address 1": "10 Rue de Rivoli",
      "Address 2": "",
      ZIP: "75001",
      DOB: "January 1st 0001, 2:20:54 am",
      "Sales Agent": "ftdnew7",
      "Retention Agent": "John Doe",
      Pool: "French desk tlv 7",
      "Site Access": "true",
      "Site Version": "EN",
      "Campaign Name": "WinterCampaign",
      "UTM Source": "Facebook",
      "UTM Medium": "Social",
      "Created Date": "December 11th 2024, 3:14:18 pm",
      "Updated By": "System-BrandsMaster",
      "Updated Date": "December 11th 2024, 3:14:21 pm",
    },
  ];

  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [leadToSend, setLeadToSend] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedData(rowData);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleChange = (key, value) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLeadToSend(selectedData);
    setShowModal(false);
  };

  return (
    <div className="col-12">
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Table striped hover className="p-0 mb-4">
          <thead>
            <tr>
              {Object.keys(dataToShow[0]).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataToShow.map((item, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(item)}
                style={{ cursor: "pointer" }}
              >
                {Object.values(item).map((value, i) => (
                  <td key={i}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier l'Historique</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedData && (
            <div>
              <Form
                onSubmit={handleSubmit}
                className="grid-container text-black"
              >
                {Object.keys(selectedData).map((key) => (
                  <div className="grid-item p-0 me-1" key={key}>
                    <div className="mx-2 mb-1">{key}</div>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedData[key]}
                      onChange={(e) => handleChange(key, e.target.value)}
                      readOnly={
                        key === "Dealer ID" ||
                        key === "Created Date" ||
                        key === "Updated Date"
                      }
                    />
                  </div>
                ))}
                <div className="grid-item p-0 me-1 full-width mt-2">
                  <Button
                    type="submit"
                    className="border pointer btn btn-green text-black me-2 col-12"
                  >
                    Valider
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {leadToSend && (
        <div className="mt-4">
          <h6>Objet à envoyer:</h6>
          <pre>{JSON.stringify(leadToSend, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default History;
