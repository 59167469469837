import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

function ChartRadar(props) {
  // Setting up the state with useState
  const [chartOptions, setChartOptions] = useState({
    series:  [
      {
          "name": "xxx",
          "data": [
              0
          ],
          "desk": 0
      }
  ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: 'end', // 'around', 'end'
          borderRadiusWhenStacked: 'last', // 'all', 'last'
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      xaxis: {
        type: 'string',
        categories: [],
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    }
  });

  // Effect to update state if props.dataPur or props.desk change
  useEffect(() => {
    const perfectSeries = props.dataPur?.map((item) => {
      console.log(item)
      return {
        name: item.brand,
        data: [Math.abs(Math.round(item.amount, 0))],
        desk : item.desk
      };
    });
    console.log(perfectSeries, 'perfectSeries');
    setChartOptions(prevOptions => ({
      ...prevOptions,
      series: perfectSeries ,
      options: {
        ...prevOptions.options,
        xaxis: {
          ...prevOptions.options.xaxis,
          categories: [perfectSeries[0]?.desk] 
        }
      }
    }));
  }, [props.dataPur]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartOptions.options} series={chartOptions.series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default ChartRadar;