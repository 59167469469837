import React, { useEffect, useState } from "react";

const ShowDetailsLead = () => {
  const [copied, setCopied] = useState(false);
  const [showCodesTimer, setShowCodesTimer] = useState(5);
  const [showCodes, setShowCodes] = useState(false);


  const dataToUpdate = [
    {
      CurrentTime: "12/12/2024 - 11:25",
      Details: "Client details section",
      ClientID: 259284,
      DialerID: 302913,
      FullName: "Genetate Cecile",
      Email: "restricted",
      Codes: "None",
      PhoneNumber1: "+33XXXXXXX",
      MTLogin: "#678293200",
      Country: "France",
      City: "Paris",
      State: "None",
      ZipCode: "75001",
      Address1: "Some Street, 123",
      Address2: "Optional Address",
      RetentionAgent: "French desk tv 7",
      SalesAgent: "Unassigned",
      SignedTerms: false,
      VerifiedDocs: "Not set",
      Status: "New",
      IsVerified: false,
      CreatedDate: "11/12/2024 03:14 PM",
      FTDDate: "11/12/2024 03:14 PM",
      TACurrency: "EUR",
      ConvertedDate: "11/12/2024 03:14 PM",
      PaymentProfile: 1,
      ClientAreaAccess: true,
      ActualClientIP: "192.168.1.1",
      ManagedClient: false,
      Subscribed: true,
      BalanceWD: "N/A",
    },
  ];

  useEffect(() => {
    let timer;
    if (showCodesTimer === 0) {
      setShowCodes(false);
    }
    if (showCodesTimer > 0) {
      timer = setTimeout(() => {
        setShowCodesTimer(showCodesTimer - 1);
      }, 1000);
    } else if (showCodesTimer === 0) {
      setCopied(false);
    }
    return () => clearTimeout(timer);
  }, [showCodesTimer]);


  const handleShowValue = (value) => {
    setShowCodes(true);
    setShowCodesTimer(5); // Initialiser le timer à 5 secondes
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
      // setCopyTimer(5);
    });
  };

  return (
    <>
      <div className="col-6">
        {dataToUpdate.map((item, index) => (
          <div key={index} className="">
            {Object.entries(item)
              .slice(0, 15)
              .map(([key, value]) => (
                <div className="d-flex p-1 align-items-center">
                  <div key={key} className="p-0 m-0 text-black text-end col-4">
                    <strong>{key}:</strong>
                  </div>
                  <div className="col-8 text-start">
                    {key === "Codes" ? (
                      showCodes ? (
                        <div className="d-flex align-items-center">
                          <span>{value}</span>
                          {showCodesTimer > 0 && (
                            <span className="ms-2 text-muted text-black">
                              {showCodesTimer}s
                            </span>
                          )}
                          <button
                            className="btn bg-vertlight btn-sm ms-2 p-1 px-3 text-black"
                            onClick={() => handleCopy(value)}
                          >
                            Copier
                          </button>
                          {copied && (
                            <span className="ms-2 text-success">Copié!</span>
                          )}
                        </div>
                      ) : (
                        <button
                          className="btn btn-danger rounded-1 p-1 px-3"
                          onClick={() => handleShowValue(value)}
                        >
                          Afficher
                        </button>
                      )
                    ) : (
                      <>{value}</>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
      <div className="col-6">
        {dataToUpdate.map((item, index) => (
          <div key={index} className="">
            {Object.entries(item)
              .slice(15, 31)
              .map(([key, value]) => (
                <div className="d-flex p-1">
                  <div key={key} className="p-0 m-0 text-black text-end col-4">
                    <strong>{key}:</strong>
                  </div>
                  <div className="col-8 text-start">{value}</div>
                </div>
              ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default ShowDetailsLead;
