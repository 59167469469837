import React, { useState } from "react";
import { Form, Button, Table, Modal } from "react-bootstrap";

const WithdrawalRequestLead = () => {
  const dataToShow = [
    {
      "Transaction ID": "134931",
      "Trading Account ID": "49368",
      "Praxis Trace ID": "123456789",
      "PSP Transaction ID": "FTD_WIRE123",
      "Client ID": "289072",
      Name: "Phillipe Georges Alfred Tedesco",
      Email: "phillipe.tedesco@example.com",
      "Trading Account Login": "495962688",
      PSP: "Xfinance_Crypto",
      "Fund Processor": "Xfinance",
      Status: "Approved",
      Type: "Withdrawal",
      "Card Number": "**** **** **** 1234",
      "Card Expiry": "12/2025",
      Note: "Website",
      "Is Fake?": "No",
      "Created Date": "December 6th 2024, 10:39:31 am",
      "Approved Date": "December 6th 2024, 11:06:21 am",
      Currency: "EUR",
      "Conversion Rate to USD": "1.05856",
      Balance: "12000",
      "Ded Bonus": "NaN",
      "USD Amount": "12702.72",
      Agent: "romainparisot",
      Pool: "CryptoWithdrawal",
    },
    {
      "Transaction ID": "134936",
      "Trading Account ID": "49373",
      "Praxis Trace ID": "123456794",
      "PSP Transaction ID": "FTD_WIRE128",
      "Client ID": "289077",
      Name: "Isabelle Moreau",
      Email: "isabelle.moreau@example.com",
      "Trading Account Login": "495962693",
      PSP: "Xfinance_Crypto",
      "Fund Processor": "Xfinance",
      Status: "Pending",
      Type: "Withdrawal",
      "Card Number": "**** **** **** 2345",
      "Card Expiry": "07/2024",
      Note: "Awaiting Confirmation",
      "Is Fake?": "No",
      "Created Date": "December 11th 2024, 9:50:40 am",
      "Approved Date": "December 11th 2024, 10:10:55 am",
      Currency: "EUR",
      "Conversion Rate to USD": "1.05700",
      Balance: "7000",
      "Ded Bonus": "NaN",
      "USD Amount": "7420.00",
      Agent: "michaelClark",
      Pool: "CryptoWithdrawal",
    },
    {
      "Transaction ID": "134941",
      "Trading Account ID": "49378",
      "Praxis Trace ID": "123456799",
      "PSP Transaction ID": "FTD_WIRE133",
      "Client ID": "289082",
      Name: "Élodie Rousseau",
      Email: "elodie.rousseau@example.com",
      "Trading Account Login": "495962698",
      PSP: "Xfinance_Forex",
      "Fund Processor": "Xfinance",
      Status: "Rejected",
      Type: "Withdrawal",
      "Card Number": "**** **** **** 2345",
      "Card Expiry": "02/2026",
      Note: "Compliance Issue",
      "Is Fake?": "No",
      "Created Date": "December 16th 2024, 7:40:20 am",
      "Approved Date": "December 16th 2024, 7:55:35 am",
      Currency: "EUR",
      "Conversion Rate to USD": "1.05500",
      Balance: "13000",
      "Ded Bonus": "NaN",
      "USD Amount": "13715.00",
      Agent: "kevinWhite",
      Pool: "ForexWithdrawal",
    }
  ];

  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [leadToSend, setLeadToSend] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedData(rowData);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleChange = (key, value) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLeadToSend(selectedData);
    setShowModal(false);
  };

  return (
    <div className="col-12">
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Table striped hover className="p-0 mb-4">
          <thead>
            <tr>
              {Object.keys(dataToShow[0]).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataToShow.map((item, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(item)}
                style={{ cursor: "pointer" }}
              >
                {Object.values(item).map((value, i) => (
                  <td key={i}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier la Demande de Retrait</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedData && (
            <div>
              <Form
                onSubmit={handleSubmit}
                className="grid-container text-black"
              >
                {Object.keys(selectedData).map((key) => (
                  <div className="grid-item p-0 me-1" key={key}>
                    <div className="mx-2 mb-1">{key}</div>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedData[key]}
                      onChange={(e) => handleChange(key, e.target.value)}
                      readOnly={
                        key === "Transaction ID" ||
                        key === "Trading Account ID" ||
                        key === "Praxis Trace ID" ||
                        key === "PSP Transaction ID" ||
                        key === "Client ID" ||
                        key === "Created Date" ||
                        key === "Approved Date"
                      }
                    />
                  </div>
                ))}
                <div className="grid-item p-0 me-1 full-width mt-2">
                  <Button
                    type="submit"
                    className="border pointer btn btn-green text-black me-2 col-12"
                  >
                    Valider
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {leadToSend && (
        <div className="mt-4">
          <h6>Objet à envoyer :</h6>
          <pre>{JSON.stringify(leadToSend, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default WithdrawalRequestLead;
