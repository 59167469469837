import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import config from "../../config/config";
import { useNavigate } from "react-router-dom";
import {
  setAdminProfil,
  setAdminAuthentified,
  setAdminDesks,
  setAdminBrands,
  setAdminDetails
} from "../../PagesCrm/Redux/slices/admin.slice";
import { setTaux} from "../../PagesCrm/Redux/slices/taux.slice";
import Cookies from 'js-cookie';
// import { getDeskNew, setDeskNew } from "../../PagesCrm/Redux/slices/actions.slice";

function Login(props) {
  const navigate = useNavigate(); 
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [twoFACode, setTwoFACode] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [errorLogin, setErrorLogin] = useState(false);

  const onLogin = (e) => {
    const objFetch = {
      pseudo: pseudo,
      pwd: password,
      otp: twoFACode
    };
    e.preventDefault();
    axios
      .post(`${config.apiAdmin}/sadmin/users/sign_in`, objFetch)
      .then(function (response) {
        if (response.data) {
          console.log(response.data)
          dispatch(setAdminProfil(response.data.profil));
          dispatch(setAdminAuthentified(true));
          dispatch(setAdminDesks(response.data.desk));
          dispatch(setAdminBrands(response.data.brands));
          dispatch(setAdminDetails(response.data));
          dispatch(setTaux({eur_usd: response.data.eur_usd, usd_eur: response.data.usd_eur}));
          localStorage.setItem("tokenadmin", response.data.token);
          Cookies.set('profil', response.data.profil, { domain: config.baseFront });

            const arrayToStore = [response.data.pseudo];
            const arrayString = JSON.stringify(arrayToStore);
            Cookies.set('srcgoogle', arrayString, { domain: config.baseFront });  


          const adminProfil = response.data.profil;
          if (adminProfil === 'sell') {
            navigate('/sell');
          } else if (adminProfil === 'tlead') {
            navigate('/tl');
          } else if (adminProfil === 'agt_supp') {
            navigate('/supp');
          } else if (adminProfil === 'ass_mng') {
            navigate('/s');
          } else if (adminProfil === 'mng_supp') {
            navigate('/ms');
          } else if (adminProfil === 'boss_supp') {
            navigate('/cs');
          } else if (adminProfil === 'manager') {
            navigate('/m');
          } else if (adminProfil === 'admin') {
            navigate('/admin');
          } else if (adminProfil === 'sadmin') {
            navigate('/sadmin');
          }
        }
      })
      .catch(function (error) {
        console.log(error.message);
        setErrorLogin(true)
      });
  };
  // const onLoginSansPreventdefault = () => {
  //   const objFetch = {
  //     pseudo: pseudo,
  //     pwd: password,
  //     // otp: twoFACode,
  //     otp: '000000',
  //   };
  //   axios
  //     .post(`${config.apiAdmin}/sadmin/users/sign_in`, objFetch)
  //     .then(function (response) {
  //       if (response.data) {
  //         dispatch(setAdminProfil(response.data.profil));
  //         dispatch(setAdminAuthentified(true));
  //         dispatch(setAdminDesks(response.data.desk));
  //         dispatch(setAdminBrands(response.data.brands));
  //         dispatch(setAdminDetails(response.data));
  //         localStorage.setItem("tokenadmin", response.data.token);
  //         dispatch(setDeskNew(false));
  //       }
  //     })
  //     .catch(function (error) {
  //       dispatch(setDeskNew(false));
  //       console.log(error.message);
  //       setErrorLogin(true)
  //     });
  // };
  // useEffect(() => {
  //   if (is_desknew) {
  //     console.log("j etre ici")
  //     onLoginSansPreventdefault();
  //   }
  // }, [is_desknew]);

  return (
    <div className="page-wraper">
      <div className="authincation">
        <div className="container-fluid">
          <div className="row h-100 bg-star">
            <div className="col-lg-6 col-md-12 col-sm-12 mx-auto align-self-center">
              <div className="login-form card" style={{bacgroundColor: "#b2b4b38f!important"}}  >
                <div className="mb-3">
                  <h3 className="title fs-32 text-center">Login</h3>
                </div>
                <form onSubmit={onLogin}>
                  <div className="mb-4">
                    <label className="mb-1 text-dark">Pseudo</label>
                    <input
                      placeholder=""
                      type="pseudo"
                      className="form-control"
                      value={pseudo}
                      onChange={(e) => setPseudo(e.target.value)}
                      autoComplete="username"
                    />
                  </div>
                  <div className="mb-4 position-relative">
                    <label className="mb-1 text-dark">Password</label>
                    <input
                      type={`${showPassword ? "text" : "password"}`}
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                    />
                    <span
                      className="show-pass eye"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword === false ? (
                        <i className="fa fa-eye-slash" />
                      ) : (
                        <i className="fa fa-eye" />
                      )}
                    </span>
                  </div>
                  <div className="mb-4 position-relative">
                    <label className="mb-1 text-dark">2FA code</label>
                    <input
                      type={`text`}
                      className="form-control"
                      value={twoFACode}
                      onChange={(e) => setTwoFACode(e.target.value)}
                    />
                  </div>
                  
                    {errorLogin && (<div className="fs-16 text-red text-center">Connection failed. Please try again.</div>)}
                  
                  <div className="text-center mb-4">
                    <button
                      type="submit"
                      className="btn btn-primary light btn-block rounded-5 text-white bg-green-login"
                    >
                      Log in
                    </button>
                  </div>
                  <h6 className="login-title">
                    <span
                      style={{ textDecoration: "underline" }}
                      className="mx-2"
                    >
                      Forget your password
                    </span>
                  </h6>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // errorMessage: state.auth.errorMessage,
    // successMessage: state.auth.successMessage,
    // showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
