import React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SVGICON } from "../../constant/theme";
import Cookies from 'js-cookie'; // Assurez-vous d'avoir installé js-cookie
import config from '../../../config/config'; // Importez votre configuration si nécessaire

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function LogoutPage(props) {

  const handleLogout = () => {
    // Supprimer le token du localStorage
    localStorage.removeItem('tokenadmin');

    // Supprimer les cookies
    Cookies.remove('goat', { domain: config.baseFront });
    Cookies.remove('auth', { domain: config.baseFront });

    // Forcer un rechargement complet de la page
    window.location.href = '/login';
  };

  return (
    <a className="logout-side-btn" style={{cursor: "pointer"}} onClick={handleLogout}>
      {SVGICON.Power} <span>Logout</span>
    </a>
  );
}

export default withRouter(connect()(LogoutPage));