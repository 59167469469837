import { createSlice } from "@reduxjs/toolkit";

export const notifSocketReducer = createSlice({
  name: "notifSocket",
  initialState: {

    sockets: [],

    dringSockets: [],
    listenAllDesks : [],
    notifs : [], 
    chats: []

  },
  reducers: {

    setSockets: (state, { payload }) => {
      // Ajoute l'objet reçu au tableau newSocket
      if (payload !== undefined) {
        state.sockets.push(payload);
      }
    },

    setListenAllDesks: (state, { payload }) => {
      // Ajoute l'objet reçu au tableau newSocket
      
      if (payload !== undefined) {
        state.listenAllDesks.push(payload);
      }
      console.log( "state.listenAllDesks", state.listenAllDesks)
    },

    deleteSockets: (state, { payload }) => {
      // Ajoute l'objet reçu au tableau newSocket
        state.sockets = [];
    },

    setDringSockets: (state, { payload }) => {
      // Ajoute l'objet reçu au tableau newSocket
      if (payload !== undefined) {
        state.dringSockets.push(payload);
      }
    },

    setNotifs: (state, { payload }) => {
      // Ajoute l'objet reçu au tableau newSocket
      if (payload !== undefined) {
        state.notifs = payload;
      }
    },

    setChats: (state, { payload }) => {
      // Ajoute l'objet reçu au tableau newSocket
      if (payload !== undefined) {
        state.chats = payload;
      }
    },

  },
});

export const { setSockets, deleteSockets, setDringSockets, setNotifs, setChats, setListenAllDesks } = notifSocketReducer.actions;


export const getSockets = (state) => {
  return state.notifSocket.sockets;
};
export const getListenAllDesks = (state) => {
  return state.notifSocket.listenAllDesks;
};

export const getSocketsDrings = (state) => {
  return state.notifSocket.dringSockets;
};

export const getNotifs = (state) => {
  return state.notifSocket.notifs;
};

export const getChats = (state) => {
  return state.notifSocket.chats;
};

export default notifSocketReducer.reducer;