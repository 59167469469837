import { SVGICON } from "../../constant/theme";
import { useSelector } from "react-redux";
import { getAdminProfil } from "../../../PagesCrm/Redux/slices/admin.slice";
import svg from "../../../images/withdraw-dollar-sign-svgrepo-com.svg";

export const useMenuList = () => {
  const adminProfil = useSelector(getAdminProfil);

  if (adminProfil === "sadmin") {
    return [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "sadmin",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "sadmin_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "sadmin_tab",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        contentMain: [
          { title: "Psp", to: "m_psp" },
          { title: "Platforme", to: "m_neobank" },
          { title: "Banque client", to: "m_bankclient" },
          { title: "Brand", to: "sadmin_Brand" },
          { title: "Desk", to: "sadmin_desk" },
        ],
        contentSub: [
          { title: "Utilisateurs", to: "m_params" },
          { title: "Targets", to: "m_targets" },
          { title: "Alerts", to: "m_alerts" },
        ],
      },
    ];
  } else if (adminProfil === "manager") {

    let menuItems = [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "m",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "m_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "m_tab",
      },
      {
        title: "Leads",
        iconStyle: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-people text-black"
            viewBox="0 0 16 16"
          >
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
          </svg>
        ),
        to: "m_leads",
      },
      {
        title: "Performance",
        iconStyle: SVGICON.CheckRight,
        content: [
          { title: "Journalière", to: "m_performance" },
          { title: "Mensuelle", to: "m_performance_monthly" },
        ],
        // to: "m_performance",
      },
      {
        title: "Calls",
        iconStyle: SVGICON.CallIcon,
        to: "m_calls",
      },
      {
        title: "Retraits",
        // iconStyle: SVGICON.CheckRight,
        iconStyle: SVGICON.Retraits,
        to: "m_retraits",
      },
      {
        title: "Documents",
        // iconStyle: SVGICON.CheckRight,
        iconStyle: SVGICON.Trello,
        to: "m_documents",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          // { title: "Utilisateurs", to: "admin_params" },
          // { title: "Targets", to: "admin_targets" },
          // { title: "Alerts", to: "admin_alerts" },
          { title: "Utilisateurs", to: "m_params" },
          { title: "Targets", to: "m_targets" },
          { title: "Alerts", to: "m_alerts" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          // { title: "Dashboard", to: "m", iconStyle: SVGICON.Home },
          { title: "Statistiques", to: "m_stats", iconStyle: SVGICON.Charts },
          { title: "Contrats", to: "m_tab", iconStyle: SVGICON.Forms },
          {
            title: "Performance",
            // to: "m_performance",
            iconStyle: SVGICON.CheckRight,
            content: [
              { title: "Journalière", to: "m_performance" },
              { title: "Mensuelle", to: "m_performance_monthly" },
            ],
          },
          { title: "Calls", to: "m_calls", iconStyle: SVGICON.CallIcon },
          { title: "Retraits", to: "m_retraits", iconStyle: SVGICON.Retraits },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              { title: "Utilisateurs", to: "m_params" },
              { title: "Targets", to: "m_targets" },
              { title: "Alerts", to: "m_alerts" },
            ],
          },
        ],
      },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;
  } else if (adminProfil === "tlead") {

    let menuItems = [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "tl",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "tl_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "tl_tab",
      },
      {
        title: "Calls",
        iconStyle: SVGICON.CallIcon,
        to: "tl_calls",
      },
      {
        title: "Retraits",
        // iconStyle: SVGICON.CheckRight,
        iconStyle: SVGICON.Retraits,
        to: "tl_retraits",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          // { title: "Utilisateurs", to: "admin_params" },
          // { title: "Targets", to: "admin_targets" },
          // { title: "Alerts", to: "admin_alerts" },
          { title: "Utilisateurs", to: "tl_params", iconStyle: SVGICON.Forms },
          { title: "Targets", to: "tl_targets", iconStyle: SVGICON.Forms },
          { title: "Alerts", to: "tl_alerts" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          // { title: "Dashboard", to: "admin" },
          { title: "Statistiques", to: "tl_stats", iconStyle: SVGICON.Charts },
          { title: "Contrats", to: "tl_tab", iconStyle: SVGICON.Forms },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              {
                title: "Utilisateurs",
                to: "tl_params",
                iconStyle: SVGICON.Forms,
              },
              { title: "Targets", to: "tl_targets", iconStyle: SVGICON.Forms },
              { title: "Alerts", to: "tl_alerts" },
            ],
          },
        ],
      },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;

  } else if (adminProfil === "admin") {
    return [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "admin",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "admin_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "admin_tab",
      },
      {
        title: "Calls",
        iconStyle: SVGICON.CallIcon,
        to: "admin_calls",
      },
      {
        title: "Retraits",
        // iconStyle: SVGICON.CheckRight,
        iconStyle: SVGICON.Retraits,
        to: "admin_retraits",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          // { title: "Utilisateurs", to: "admin_params" },
          // { title: "Targets", to: "admin_targets" },
          // { title: "Alerts", to: "admin_alerts" },
          { title: "Psp", to: "admin_psp" },
          { title: "Platforme", to: "admin_neobank" },
          { title: "Banque client", to: "admin_bankclient" },
          { title: "Brand", to: "admin_Brand" },
          { title: "Desk", to: "admin_desk" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          // { title: "Dashboard", to: "admin" },
          {
            title: "Statistiques",
            to: "admin_desk_details",
            iconStyle: SVGICON.Charts,
          },
          { title: "Contrats", to: "admin_tab", iconStyle: SVGICON.Forms },
          { title: "Calls", to: "admin_calls", iconStyle: SVGICON.CallIcon },
          {
            title: "Retraits",
            to: "admin_retraits",
            iconStyle: SVGICON.Retraits,
          },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              {
                title: "Utilisateurs",
                to: "admin_params",
                iconStyle: SVGICON.Forms,
                id: 0,
              },
              {
                title: "Targets",
                to: "admin_targets",
                iconStyle: SVGICON.Forms,
                id: 1,
              },
              { title: "Alerts", to: "admin_alerts", id: 2 },
            ],
          },
        ],
      },

      // {
      //   title: "Configurations_Desk",
      //   // iconStyle: SVGICON.Setting,
      //   content: [
      //     { title: "Utilisateurs", to: "admin_params" },
      //     { title: "Targets", to: "admin_targets" },
      //     { title: "Alerts", to: "admin_alerts" },
      //     // { title: "Psp", to: "admin_psp" },
      //     // { title: "Platforme", to: "admin_neobank" },
      //     // { title: "Banque client", to: "admin_bankclient" },
      //     // { title: "Brand", to: "admin_Brand" },
      //     // { title: "Desk", to: "admin_desk" },
      //   ],
      // },
    ];
  } else if (adminProfil === "boss_supp") {

    let menuItems = [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "cs",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "cs_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "cs_tab",
      },
      {
        title: "Calls",
        iconStyle: SVGICON.CallIcon,
        to: "cs_calls",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          // { title: "Utilisateurs", to: "admin_params" },
          // { title: "Targets", to: "admin_targets" },
          // { title: "Alerts", to: "admin_alerts" },
          { title: "Psp", to: "cs_psp" },
          { title: "Platforme", to: "cs_neobank" },
          { title: "Banque client", to: "cs_bankclient" },
          { title: "Brand", to: "cs_Brand" },
          { title: "Desk", to: "cs_desk" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          { title: "Dashboard", to: "cs", iconStyle: SVGICON.Charts },
          { title: "Statistiques", to: "cs_stats", iconStyle: SVGICON.Charts },
          { title: "Contrats", to: "cs_tab", iconStyle: SVGICON.Forms },
          { title: "Calls", to: "cs_calls", iconStyle: SVGICON.CallIcon },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              { title: "Utilisateurs", to: "cs_params" },
              // { title: "Targets", to: "cs_targets" },
              { title: "Alerts", to: "cs_alerts" },
            ],
          },
        ],
      },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;
  } else if (adminProfil === "sell") {

    let menuItems = [ {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "sell",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "sell_tab",
      },
      {
        title: "Documents",
        iconStyle: SVGICON.Trello,
        to: "document",
      },
      {
        title: "Performance",
        iconStyle: SVGICON.CheckRight,
        to: "m_performance_monthly_agent",

        // to: "m_performance",
      },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;
  } else if (adminProfil === "agt_supp") {

    let menuItems = [ {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "supp",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "supp_tab",
      },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;
  } else if (adminProfil === "mng_supp") {
    return [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "ms",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "ms_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "ms_tab",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          // { title: "Utilisateurs", to: "admin_params" },
          // { title: "Targets", to: "admin_targets" },
          // { title: "Alerts", to: "admin_alerts" },
          { title: "Utilisateurs", to: "ms_params", iconStyle: SVGICON.Forms },
          // { title: "Targets", to: "ms_targets",iconStyle: SVGICON.Forms },
          { title: "Alerts", to: "ms_alerts" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          { title: "Dashboard", to: "ms", iconStyle: SVGICON.Home },
          { title: "Statistiques", to: "ms_stats", iconStyle: SVGICON.Charts },
          { title: "Contrats", to: "ms_tab", iconStyle: SVGICON.Forms },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              {
                title: "Utilisateurs",
                to: "ms_params",
                iconStyle: SVGICON.Forms,
              },
              // { title: "Targets", to: "ms_targets",iconStyle: SVGICON.Forms },
              { title: "Alerts", to: "ms_alerts" },
            ],
          },
        ],
      },
    ];
  } else if (adminProfil === "ass_mng") {

    let menuItems =  [
      {
        title: "Dashboard",
        iconStyle: SVGICON.Home,
        to: "s",
      },
      {
        title: "Statistiques",
        iconStyle: SVGICON.Charts,
        to: "s_stats",
      },
      {
        title: "Contrats",
        iconStyle: SVGICON.Forms,
        to: "s_tab",
      },
      {
        title: "Calls",
        iconStyle: SVGICON.CallIcon,
        to: "s_calls",
      },
      {
        title: "Retraits",
        // iconStyle: SVGICON.CheckRight,
        iconStyle: SVGICON.Retraits,
        to: "s_retraits",
      },
      {
        title: "Configurations",
        iconStyle: SVGICON.Setting,
        content: [
          { title: "Utilisateurs", to: "s_params" },
          { title: "Targets", to: "s_targets" },
          { title: "Alerts", to: "s_alerts" },
        ],
      },
      {
        title: "Desks",
        iconStyle: SVGICON.Forms,
        content: [
          { title: "Statistiques", to: "s_stats", iconStyle: SVGICON.Charts },
          { title: "Contrats", to: "s_tab", iconStyle: SVGICON.Forms },
          { title: "Calls", to: "s_calls", iconStyle: SVGICON.CallIcon },
          { title: "Retraits", to: "s_retraits", iconStyle: SVGICON.Retraits },
        ],
        children: [
          // Corrected spelling here
          {
            title: "Configurations_Desk",
            iconStyle: SVGICON.Setting,
            content: [
              { title: "Utilisateurs", to: "s_params" },
              { title: "Targets", to: "s_targets" },
              { title: "Alerts", to: "s_alerts" },
            ],
          },
        ],
      },
    ];

    if (process.env.REACT_APP_CONFIG === "proddub") {
      menuItems = menuItems.filter((item) => item.title !== "Documents");
    }

    return menuItems;
  }
};
