import React, { useState } from "react";
import { Form, Button, Table, Modal } from "react-bootstrap";

const TransactionsLead = () => {
  const dataToShow = {
    ID: "135500",
    Login: "49467320290",
    Currency: "EUR",
    Amount: "1",
    "USD Conversion Rate": "1.0566",
    "USD Amount": "1.05",
    "Created Date": "December 11th 2024, 3:14:17 pm",
    "Praxis Trace ID": "FTD_Wire466810",
    "PSP Transaction ID": "FTD_Wire466810",
    PSP: "FTD_Wire",
    "Fund Processor": "WoocomPraxis",
    Status: "Approved",
    Type: "Deposit",
    "Card Number": "**** **** **** 1234",
    "Card Expiry": "12/2025",
    "Is Fake": "true",
    "PSP Created Date": "December 11th 2024, 3:14:17 pm",
    Note: "Approved (FTD_Wire466)",
  };

  const [formData, setFormData] = useState(dataToShow);
  const [leadToSend, setLeadToSend] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLeadToSend(formData);
    setShowModal(false);
  };

  const handleRowClick = () => {
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="col-12">
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Table striped hover className="p-0 mb-4">
          <thead>
            <tr>
              {Object.keys(dataToShow).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr onClick={handleRowClick} style={{ cursor: "pointer" }}>
              {Object.values(dataToShow).map((value, index) => (
                <td key={index}>{value}</td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier les Détails de la Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form onSubmit={handleSubmit} className="grid-container text-black">
              {Object.keys(formData).map((key) => (
                <div className="grid-item p-0 me-1" key={key}>
                  <div className="mx-2 mb-1">{key}</div>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={formData[key]}
                    onChange={(e) => handleChange(key, e.target.value)}
                    readOnly={key === "ID"}
                  />
                </div>
              ))}
              <div className="grid-item p-0 me-1 full-width mt-2">
                <Button
                  type="submit"
                  className="border pointer btn btn-green text-black me-2 col-12"
                >
                  Valider
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {leadToSend && (
        <div className="mt-4">
          <h6>Objet à envoyer:</h6>
          <pre>{JSON.stringify(leadToSend, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default TransactionsLead;
