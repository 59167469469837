import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import ReactSelect from "react-select";
import { Offcanvas } from "react-bootstrap";
import ShowDetailsLead from "./components/ShowDetailsLead";
import NotesLead from "./components/NotesLead";
import BankDetailLead from "./components/BankDetailLead";
import CallsLead from "./components/CallsLead";
import DocumentsLead from "./components/DocumentsLead";
import EmailHistoryLead from "./components/EmailHistoryLead";
import History from "./components/History";
import ReassignLogsLead from "./components/ReassignLogsLead";
import TrandingAccountsLead from "./components/TrandingAccountsLead";
import TransactionsLead from "./components/TransactionsLead";
import WithDrawalRequestLead from "./components/WithdrawalRequestLead";
import Avatar from "../../../images/avataaars (2).svg"

const UpdateLead = ({ lead }) => {
  const [crmId, setCrmId] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [s1, setS1] = useState(null);
  const [s2, setS2] = useState(null);

  const [leadToSend, setLeadToSend] = useState(null);
  const [selectedSection, setSelectedSection] = useState("lead"); // Section 5 initialement sélectionnée
  const [titletoShow, setTitletoShow] = useState("lead");



  useEffect(() => {
    if (lead) {
      setCrmId(lead.crm_id || "");
      setNom(lead.nom || "");
      setPrenom(lead.prenom || "");
      setS1(
        lead.s1 !== undefined
          ? { value: lead.s1, label: lead.s1.toString() }
          : null
      );
      setS2(
        lead.s2 !== undefined
          ? { value: lead.s2, label: lead.s2.toString() }
          : null
      );
    }
  }, [lead]);

  if (!lead) {
    return <div>Aucun lead sélectionné.</div>;
  }

  const sOptions = [
    { value: 1, label: "1" },
    { value: 0, label: "0" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedLead = {
      crm_id: crmId,
      nom,
      prenom,
      s1: s1 ? s1.value : lead.s1,
      s2: s2 ? s2.value : lead.s2,
      idleads: lead.idleads,
      idusers: lead.idusers,
      created_at: lead.created_at,
    };
    console.log("Lead à envoyer:", updatedLead);
    setLeadToSend(updatedLead);
    // Réinitialiser les champs du formulaire si nécessaire
    // setCrmId("");
    // setNom("");
    // setPrenom("");
    // setS1(null);
    // setS2(null);
    // Ajouter la logique d'envoi du lead ici
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#26e87f" : provided.borderColor,
      backgroundColor: "white",
      cursor: "pointer",
      height: "45px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      padding: "0px 5px",
      boxShadow: state.isFocused ? "0 0 0 1px #26e87f" : provided.boxShadow,
      "&:hover": {
        borderColor: "#26e87f",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      cursor: "pointer",
      textAlign: "center",
      width: "100%",
      //   color: "#26e87f",
    }),
    placeholder: (provided) => ({
      ...provided,
      //   color: "#26e87f",
      textAlign: "center",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#26e87f",
      backgroundColor: state.isSelected ? "#e6ffe6" : "white",
      "&:hover": {
        backgroundColor: "#e6ffe6",
        color: "#26e87f",
      },
      cursor: "pointer",
      textAlign: "center",
      borderRadius: "5px",
      lineHeight: "29px",
    }),
  };

  const toggleSection = (sectionNumber) => {
    console.log("sectionNumber",sectionNumber)
    setTitletoShow(sectionNumber);
    setSelectedSection((prev) =>
      prev === sectionNumber ? null : sectionNumber
    );
  };
// console.log(selectedSection,selectedSection)
  return (
    <>
      <Offcanvas.Header className="center-flex p-1">
        <div
          className={`col-2 bg-light rounded-circle-lead center-flex pointer profil-lead grid-item-lead ${
            selectedSection === "lead" ? "selected-lead" : ""
          }`}
          onClick={() => toggleSection("lead")}
        >
          <img src={Avatar} alt="" style={{height:"80px", marginBottom:"10px"}} />
        </div>
        <div className="col-10">
          <div className="grid-container-up-lead pointer">
            {[
              "Notes",
              "Bank Details",
              "Email History",
              "Trading Accounts",
              "Transactions",
              "Calls",
              "History",
              "Documents",
              "Reassign Logs",
              "Withdrawal Request",
            ].map((section) => {
              return(
              <div
                key={section}
                className={`grid-item-lead center-flex ${
                  selectedSection === section ? "selected-lead" : ""
                }`}
                onClick={() => toggleSection(section)}
              >
                {section}
              </div>
            )})}
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex mt-5">
        {titletoShow === "lead" && <ShowDetailsLead />}
        {titletoShow === "Notes" && <NotesLead />}
        {titletoShow === "Bank Details" && <BankDetailLead />}
        {titletoShow === "Email History" && <EmailHistoryLead />}
        {titletoShow === "Trading Accounts" && <TrandingAccountsLead />}
        {titletoShow === "Transactions" && <TransactionsLead />}
        {titletoShow === "Calls" && <CallsLead />}
        {titletoShow === "History" && <History />}
        {titletoShow === "Documents" && <DocumentsLead />}
        {titletoShow === "Reassign Logs" && <ReassignLogsLead />}
        {titletoShow === "Withdrawal Request" && <WithDrawalRequestLead />}
      </Offcanvas.Body>
      {/* <Offcanvas.Body>
        <div>
          <Form onSubmit={handleSubmit} className="grid-container text-black">
            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Crm_id</div>
              <input
                as={Row}
                type="text"
                className="form-control"
                placeholder=""
                required
                value={crmId}
                onChange={(e) => setCrmId(e.target.value)}
                readOnly
              />
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Prenom</div>
              <input
                as={Row}
                type="text"
                className="form-control"
                placeholder=""
                required
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
              />
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Nom</div>
              <input
                as={Row}
                type="text"
                className="form-control"
                placeholder=""
                required
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">S1</div>
              <ReactSelect
                value={s1}
                onChange={setS1}
                options={sOptions}
                styles={customStyles}
              />
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">S2</div>
              <ReactSelect
                value={s2}
                onChange={setS2}
                options={sOptions}
                styles={customStyles}
              />
            </div>


            <div className="grid-item p-0 me-1 full-width mt-2">
              <Button
                type="submit"
                className="border pointer btn btn-green text-black me-2 col-12"
              >
                Valider
              </Button>
            </div>
          </Form>

          {leadToSend && (
            <div className="mt-4">
              <h6>Objet à envoyer:</h6>
              <pre>{JSON.stringify(leadToSend, null, 2)}</pre>
            </div>
          )}
        </div>
      </Offcanvas.Body> */}
    </>
  );
};

export default UpdateLead;
