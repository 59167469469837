import React from "react";
import objRowTable from "./objRowTable";
import arrowSort from "./arrowSort";

const TrTable = ({ stateP, stateRow }) => {
  console.log("stateRow", stateRow);
  console.log("stateP", stateP);
  return (
    <tr className="titre-tableau text-center">
      <th
        className=" fs-14 fw-bold text-black"
        // onClick={() => {
        //   stateRow(objRowTable.id.key, objRowTable.id.type);
        // }}
      >
        <div className="d-flex align-items-center justify-content-center">
          {/* <div>ID</div> */}
          {/* <div className="ms-1">{arrowSort(stateP.id.etat)}</div> */}
        </div>
      </th>
      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.id.key, objRowTable.id.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>ID</div>
          <div className="ms-1">{arrowSort(stateP.id.etat)}</div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        // onClick={() => {
        //   stateRow(objRowTable.id.key, objRowTable.id.type);
        // }}
      >
        <div className="d-flex align-items-center justify-content-center">
          {/* <div>ID</div>
          <div className="ms-1">{arrowSort(stateP.id.etat)}</div> */}
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.mtlogin.key, objRowTable.mtlogin.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>MT Login</div>
          <div className="ms-1">{arrowSort(stateP.mtlogin.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.full_name.key, objRowTable.full_name.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Full Name</div>
          <div className="ms-1">{arrowSort(stateP.full_name.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.retentionagentname.key,
            objRowTable.retentionagentname.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Retention Agent Name</div>
          <div className="ms-1">
            {arrowSort(stateP.retentionagentname.etat)}
          </div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.is_ftd.key, objRowTable.is_ftd.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Is FTD</div>
          <div className="ms-1">{arrowSort(stateP.is_ftd.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.country.key, objRowTable.country.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Country</div>
          <div className="ms-1">{arrowSort(stateP.country.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.status.key, objRowTable.status.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Status</div>
          <div className="ms-1">{arrowSort(stateP.status.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.pool.key, objRowTable.pool.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Pool</div>
          <div className="ms-1">{arrowSort(stateP.pool.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.created_date.key, objRowTable.created_date.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>created date</div>
          <div className="ms-1">{arrowSort(stateP.created_date.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.converted_date.key,
            objRowTable.converted_date.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Converted Date</div>
          <div className="ms-1">{arrowSort(stateP.converted_date.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.ftd_date.key, objRowTable.ftd_date.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>FTD Date</div>
          <div className="ms-1">{arrowSort(stateP.ftd_date.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.actual_client_ip.key,
            objRowTable.actual_client_ip.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Actual Client IP</div>
          <div className="ms-1">{arrowSort(stateP.actual_client_ip.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.verified.key, objRowTable.verified.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Verified</div>
          <div className="ms-1">{arrowSort(stateP.verified.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.unique_id.key, objRowTable.unique_id.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Unique ID</div>
          <div className="ms-1">{arrowSort(stateP.unique_id.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.totaldepositamount.key,
            objRowTable.totaldepositamount.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Total Deposit Amount</div>
          <div className="ms-1">
            {arrowSort(stateP.totaldepositamount.etat)}
          </div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.laststatusdate.key,
            objRowTable.laststatusdate.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Last Status Date</div>
          <div className="ms-1">{arrowSort(stateP.laststatusdate.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.totalwithdrawalamount.key,
            objRowTable.totalwithdrawalamount.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Total Withdrawal Amount</div>
          <div className="ms-1">
            {arrowSort(stateP.totalwithdrawalamount.etat)}
          </div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.lastcalldate.key, objRowTable.lastcalldate.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Last Call Date</div>
          <div className="ms-1">{arrowSort(stateP.lastcalldate.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.firstcalldate.key,
            objRowTable.firstcalldate.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>First Call Date</div>
          <div className="ms-1">{arrowSort(stateP.firstcalldate.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.city.key, objRowTable.city.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>City</div>
          <div className="ms-1">{arrowSort(stateP.city.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.state.key, objRowTable.state.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>State</div>
          <div className="ms-1">{arrowSort(stateP.state.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.gender.key, objRowTable.gender.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Gender</div>
          <div className="ms-1">{arrowSort(stateP.gender.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.initial_martial_status.key,
            objRowTable.initial_martial_status.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Initial Martial Status</div>
          <div className="ms-1">
            {arrowSort(stateP.initial_martial_status.etat)}
          </div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.initial_experience.key,
            objRowTable.initial_experience.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Initial Experience</div>
          <div className="ms-1">
            {arrowSort(stateP.initial_experience.etat)}
          </div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.initial_expectations.key,
            objRowTable.initial_expectations.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Initial Expectations</div>
          <div className="ms-1">
            {arrowSort(stateP.initial_expectations.etat)}
          </div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.initial_bonus.key,
            objRowTable.initial_bonus.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Initial Bonus</div>
          <div className="ms-1">{arrowSort(stateP.initial_bonus.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.initial_experienced_on.key,
            objRowTable.initial_experienced_on.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Initial Experienced On</div>
          <div className="ms-1">
            {arrowSort(stateP.initial_experienced_on.etat)}
          </div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.initial_experience_earn.key,
            objRowTable.initial_experience_earn.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Initial Experience Earn</div>
          <div className="ms-1">
            {arrowSort(stateP.initial_experience_earn.etat)}
          </div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.initial_how_to_contact.key,
            objRowTable.initial_how_to_contact.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Initial How to Contact</div>
          <div className="ms-1">
            {arrowSort(stateP.initial_how_to_contact.etat)}
          </div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.mt_last_online.key,
            objRowTable.mt_last_online.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>MT Last Online</div>
          <div className="ms-1">{arrowSort(stateP.mt_last_online.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.atompix_last_online.key,
            objRowTable.atompix_last_online.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Atompix Last Online</div>
          <div className="ms-1">
            {arrowSort(stateP.atompix_last_online.etat)}
          </div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.dialer_id.key, objRowTable.dialer_id.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Dialer ID</div>
          <div className="ms-1">{arrowSort(stateP.dialer_id.etat)}</div>
        </div>
      </th>

      <th
        className="fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.dialer_type.key, objRowTable.dialer_type.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>Dialer Type</div>
          <div className="ms-1">{arrowSort(stateP.dialer_type.etat)}</div>
        </div>
      </th>
    </tr>
  );
};

export default TrTable;
// <tr className="titre-tableau text-center">
//   <th
//     className="fixed-column fs-14 fw-bold text-black"
//     // onClick={() => {
//     //   stateRow(objRowTable.created_at.key, objRowTable.created_at.type);
//     // }}
//   >
//     <div className="d-flex align-items-center justify-content-center">
//       <div></div>
//       <div className="ms-1">
//         {arrowSort(sortRow[objRowTable.created_at.key].etat)}
//       </div>
//     </div>
//   </th>
//   <th
//     className="fixed-column fs-14 fw-bold text-black"
//     onClick={() => {
//       stateRow(objRowTable.idleads.key, objRowTable.idleads.type);
//     }}
//   >
//     <div className="d-flex align-items-center justify-content-center">
//       <div>ID</div>
//       <div className="ms-1">
//         {arrowSort(stateP.idleads.etat)}
//       </div>
//     </div>
//   </th>
//   <th
//     className="fixed-column-2 fs-14 fw-bold text-black m-auto"
//     onClick={() => {
//       stateRow(objRowTable.created_at.key, objRowTable.created_at.type);
//     }}
//   >
//     <div className="d-flex align-items-center justify-content-center">
//       <div className="">Date Création</div>
//       <div className="ms-1">
//         {/* {arrowSort(sortRow[objRowTable.created_at.key].etat)} */}
//         {arrowSort(stateP.created_at.etat)}
//       </div>
//     </div>
//   </th>
//   <th
//     className="fixed-column-3 fs-14 fw-bold text-black text-center"
//     onClick={() => {
//       stateRow(objRowTable.nom.key, objRowTable.nom.type);
//     }}
//   >
//     <div className="d-flex align-items-center justify-content-center">
//       <div className="">Nom</div>
//       <div className="ms-1">
//         {/* {arrowSort(sortRow[objRowTable.nom.key].etat)} */}
//         {arrowSort(stateP.nom.etat)}

//       </div>
//     </div>
//   </th>
//   <th
//     className="fixed-column-4 fs-14 fw-bold text-black"
//     onClick={() => {
//       stateRow(objRowTable.prenom.key, objRowTable.prenom.type);
//     }}
//   >
//     <div className="d-flex align-items-center justify-content-center">
//       <div className="">Prenom</div>
//       <div className="ms-1">
//         {/* {arrowSort(sortRow[objRowTable.prenom.key].etat)} */}
//         {arrowSort(stateP.prenom.etat)}

//       </div>
//     </div>
//   </th>
//   <th
//     className="fixed-column-5 fs-14 fw-bold text-black"
//     onClick={() => {
//       stateRow(objRowTable.crm_id.key, objRowTable.crm_id.type);
//     }}
//   >
//     <div className="d-flex align-items-center justify-content-center">
//       <div className="">Crm ID</div>
//       <div className="ms-1">
//         {/* {arrowSort(sortRow[objRowTable.crm_id.key].etat)} */}
//         {arrowSort(stateP.crm_id.etat)}

//       </div>
//     </div>
//   </th>
//   <th
//     className="fixed-column-6 fs-14 fw-bold text-black"
//     onClick={() => {
//       stateRow(
//         objRowTable.s1.key,
//         objRowTable.s1.type
//       );
//     }}
//   >
//     <div className="d-flex align-items-center justify-content-center">
//       <div className="">S1</div>
//       <div className="ms-1">
//         {/* {arrowSort(sortRow[objRowTable.s1.key].etat)} */}
//         {arrowSort(stateP.s1.etat)}

//       </div>
//     </div>
//   </th>
//   <th
//     className=" fs-14 fw-bold text-black"
//     onClick={() => {
//       stateRow(objRowTable.s2.key, objRowTable.s2.type);
//     }}
//   >
//     <div className="d-flex align-items-center justify-content-center">
//       <div className="">S2</div>
//       <div className="ms-1">
//         {/* {arrowSort(sortRow[objRowTable.s2.key].etat)} */}
//         {arrowSort(stateP.s2.etat)}

//       </div>
//     </div>
//   </th>
// </tr>
