import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { setPopup } from "../../Redux/slices/actions.slice";
import { Offcanvas, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMinutes } from "date-fns";
import { useParams } from "react-router-dom";
import useHeaderRest from "../../../utils/useHeader";
import { CircleLoader } from "react-spinners";

const AddContrat = ({ isVisible, onHide, GetAllContrats }) => {
  const [nomClient, setNomClient] = useState("");
  const [supportClient, setSupportClient] = useState("");
  const [pseudoClient, setPseudoClient] = useState("");
  const [prenomClient, setPrenomClient] = useState("");
  const [brandClient, setBrandClient] = useState("");
  const [amountClient, setAmountClient] = useState("");
  const [IsConvertion, setIsConvertion] = useState(0);
  const [IsSendVerif, setIsSendVerif] = useState(0);
  const [dateEcheance, setDateEcheance] = useState("");
  const [pspClient, setPspClient] = useState("");
  const [neoClient, setNeoClient] = useState("");
  const [bankClient, setBankClient] = useState("");
  const [argent_dispoClient, setArgent_dispoClient] = useState(0);
  const [preuveClient, setPreuveClient] = useState(0);
  const [next_callClient, setNext_callClient] = useState("");
  const [hoursClient, setHoursClient] = useState(null);
  const [encaisserClient, setEncaisserClient] = useState(0);
  const [reportClient, setReportClient] = useState(0);
  const [annulClient, setAnnulClient] = useState(0);

  const [erreurNomClient, setErreurNomClient] = useState("");
  const [erreurSupportClient, setErreurSupportClient] = useState("");
  const [erreurPseudoClient, setErreurPseudoClient] = useState("");
  const [erreurPrenomClient, setErreurPrenomClient] = useState("");
  const [erreurBrandClient, setErreurBrandClient] = useState("");
  const [erreurAmountClient, setErreurAmountClient] = useState("");
  const [erreurDateEcheance, setErreurDateEcheance] = useState("");
  const [erreurCustIdClient, setErreurCustIdClient] = useState("");

  const [psp, setPsp] = useState([]);
  const [neoBank, setNeoBank] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);

  const [customer_id, setCustomer_id] = useState("");
  const [listSupport, setListSupport] = useState([]);
  const [error, setError] = useState(false);
  const [loaderButton, setLoaderButton] = useState(false);
  const [errorVirgule, setErrorVirgule] = useState(false);
  const [listSellAndTlead, setListSellAndTlead] = useState([]);
  const [date_virC, setDate_virC] = useState(null);
  const useHeader = useHeaderRest();

  const { desk } = useParams();

  const AdminDetails = useSelector(getAdminDetails);

  let Desk = AdminDetails?.desk[0];

  const desksToAdmin = AdminDetails?.desk;

  const goodDesk = desk ? desk : Desk;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isVisible) {
      handleShowFilters();
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleShowFilters = () => {
    FetchFiltersGet("/psp/read_all", setPsp);
    FetchFiltersGet("/neobanks/read_all", setNeoBank);
    FetchFiltersGet("/customerbanks/read_all", setCustomerBank);
    FetchFiltersPost("/users/all_agt_supp_and_mng_supp", setListSupport);
    FetchFiltersPost("/users/all_sell_and_tlead", setListSellAndTlead, {
      desk: desksToAdmin,
    });
  };
  const FetchFiltersGet = (url, fonction) => {
    axios
      .get(`${config.apiAdmin}/sadmin${url}`, Header)
      .then((response) => {
        fonction(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const FetchFiltersPost = (url, fonction, obj) => {
    axios
      .post(`${config.apiAdmin}/sadmin${url}`, obj, Header)
      .then((response) => {
        fonction(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const profilToAdmin = AdminDetails?.profil;
  const IdToAdmin = AdminDetails?.idusers;
  const brandToAdmin = AdminDetails?.brand;
  const tleadToAdmin = AdminDetails?.tlead_id;
  const agt_supp_idToAdmin = AdminDetails?.agt_supp_id;

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const CreateContract = () => {
    setLoaderButton(true);

    let next_call;

    next_call =
      next_callClient === "" ? null : new Date(`${next_callClient}T00:00:00`);

    // Desk

    let obj = {
      amount: amountClient,
      desk: goodDesk,
      psp: pspClient,
      brand: brandClient,
      customerbank: bankClient,
      neobank: neoClient,
      conversion: IsConvertion,
      is_send_verif: IsSendVerif,
      paymentdudate: dateEcheance,
      argent_dispo: argent_dispoClient,
      preuve: preuveClient,
      encaisse: encaisserClient,
      is_report: reportClient,
      is_canceled: annulClient,
      next_call: next_call,
      hours: hoursClient,

      seller_id:
        profilToAdmin === "sell" ? AdminDetails?.idusers : pseudoClient,
      tlead_id: tleadToAdmin,
      agt_supp_id:
        profilToAdmin === "agt_supp" ? AdminDetails?.idusers : supportClient,

      is_active: 1,
      customer: {
        firstname: prenomClient,
        lastname: nomClient,
        crm_id: customer_id,
      },
      desk_filter:
        profilToAdmin === "sell" || profilToAdmin === "agt_supp"
          ? ""
          : goodDesk,
      limit: "",
      // seller_id_filter: IdToAdmin,
      seller_id_filter: profilToAdmin === "sell" ? IdToAdmin : pseudoClient,
      agt_supp_id_filter:
        profilToAdmin === "agt_supp" ? agt_supp_idToAdmin : supportClient,
      user: profilToAdmin,
    };
    axios
      .put(
        `${config.apiAdmin}/sadmin/contracts/create-contract-and-customer-integrate`,
        obj,
        useHeader
      )
      .then((response) => {
        setLoaderButton(false);
        setError(false);
        GetAllContrats({ data: true });
        SendPopup({
          message: "confirm",
          info: "create-contract-and-customer-integrate.",
          isActive: true,
        });
        onHide();
        resetStates();
      })
      .catch((error) => {
        console.log(error)
        setLoaderButton(false);
        SendPopup({
          message: "error",
          info: "create-contract-and-customer-integrate.",
          isActive: true,
        });
        setError(true);
      });
  };

  const optionsBrands = brandToAdmin?.map((brand) => ({
    value: brand,
    label: brand,
  }));
  const optionsPsps = psp?.map((psp) => ({
    value: psp.name,
    label: psp.name,
  }));
  const optionsNeos = neoBank?.map((neo) => ({
    value: neo.name,
    label: neo.name,
  }));
  const optionsBankClients = customerBank?.map((bank) => ({
    value: bank.name,
    label: bank.name,
  }));

  const optionsSupport = listSupport?.map((support) => ({
    value: support.idusers,
    label: support.pseudo,
    profil:
      support.profil === "agt_supp"
        ? "Support"
        : support.profil === "mng_supp"
        ? "MgSupport"
        : support.profil === "boss_supp"
        ? "BossSupport"
        : support.profil,
  }));
  const optionsPseudo = listSellAndTlead?.map((pseudo) => {
    return {
      value: pseudo.idusers,
      label: pseudo.pseudo,
      profil:
        pseudo.profil === "sell"
          ? "Vendeur"
          : pseudo.profil === "tlead"
          ? "TeamLeader"
          : pseudo.profil,
    };
  });
  const resetStates = () => {
    setPrenomClient("");
    setNomClient("");
    setSupportClient("");
    setPseudoClient("");
    setBrandClient("");
    setAmountClient("");
    setIsConvertion(0);
    setDateEcheance("");
    setPspClient("");
    setNeoClient("");
    setBankClient("");
    setArgent_dispoClient(0);
    setPreuveClient(0);
    setNext_callClient("");
    setHoursClient(null);
    setEncaisserClient(0);
    setReportClient(0);
    setAnnulClient(0);
    // setListPseudo([]);
    setError(false);
    setLoaderButton(false);

    setErreurNomClient("");
    setErreurSupportClient("");
    setErreurPseudoClient("");
    setErreurPrenomClient("");
    setErreurBrandClient("");
    setErreurAmountClient("");
    setErreurDateEcheance("");
    // setErreurPspClient("");
    // setErreurNeoClient("");
    // setErreurBankClient("");
    // setErreurNextCallClient("");
    // setErreurHoursClient("");
    setErreurCustIdClient("");
    setErrorVirgule(false);
    setCustomer_id("");
  };
  const CustomDrop = (props) => {
    return (
      <components.Option {...props}>
        <div className="d-flex justify-content-between align-items-center text-black">
          <span>{props.data.label}</span>
          <span
            style={{ color: "white" }}
            className={`badge badge-${props.data.profil}`}
          >
            {props.data.profil}
          </span>
        </div>
      </components.Option>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = true;

    // Réinitialiser les erreurs
    setErreurNomClient("");
    setErreurSupportClient("");
    setErreurPseudoClient("");
    setErreurPrenomClient("");
    setErreurBrandClient("");
    setErreurAmountClient("");
    setErreurDateEcheance("");
    setErreurCustIdClient("");

    // Validation
    if (!nomClient) {
      setErreurNomClient("Le nom est requis");
      console.log("voila1");
      isValid = false;
    }
    if (profilToAdmin !== "agt_supp") {
      if (!supportClient) {
        setErreurSupportClient("Le support est requis");
        console.log("voila2");
        isValid = false;
      }
    }
    if (profilToAdmin !== "sell") {
      if (!pseudoClient) {
        setErreurPseudoClient("Le vendeur est requis");
        console.log("voila3");
        isValid = false;
      }
    }
    if (!prenomClient) {
      setErreurPrenomClient("Le prénom est requis");
      console.log("voila4");
      isValid = false;
    }
    if (!customer_id) {
      setErreurCustIdClient("Le customerId est requis");
      console.log("voila5");
      isValid = false;
    }
    if (!brandClient) {
      setErreurBrandClient("La marque est requise");
      console.log("voila6");
      isValid = false;
    }
    if (!amountClient) {
      setErreurAmountClient("Le montant est requis");
      console.log("voila7");
      isValid = false;
    }
    if (!dateEcheance) {
      setErreurDateEcheance("La date d'échéance est requise");
      console.log("voila8");
      isValid = false;
    }
    if (isValid) {
      // Procéder à l'envoi du formulaire
      console.log("voila");
      CreateContract();
      console.log("Formulaire envoyé avec succès");
    }
  };

  const handleDate_vir = (date) => {
    if (date === null) {
      setDate_virC(null); // ou "" si vous préférez une chaîne vide
      return;
    }
    const selectedDate = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateInUTC = addMinutes(selectedDate, -timezoneOffset);
    setDate_virC(dateInUTC);
  };

  return (
    <Offcanvas
      show={isVisible}
      onHide={() => {
        onHide();
        resetStates();
      }}
      placement="end"
      style={{ width: "600px" }}
    >
      <Offcanvas.Header closeButton className="pb-0 bg-light-update">
        <Offcanvas.Title className="col-10">
          <div className="d-flex">
            <div
              className={`pointer fs-14 ms-2 me-3 p-2 b-bottom-green fw-bold`}
            >
              Nouveau Contrat
            </div>
          </div>
          <div className="border"></div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="bg-light-update">
        <div
          style={{ textDecoration: "underline" }}
          className="mb-3 fw-bold mt-1 text-black"
        >
          Informations Client
        </div>
        <form onSubmit={handleSubmit}>
          <div className="p-1 grid-container text-black">
            {profilToAdmin !== "sell" && (
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Sélection agent</div>
                <ReactSelect
                  options={optionsPseudo}
                  className={`rounded ${
                    erreurPseudoClient ? "border border-danger" : "border-0"
                  }`}
                  classNamePrefix="select"
                  name="alias"
                  placeholder=""
                  onChange={(selectedOption) =>
                    setPseudoClient(selectedOption.value)
                  }
                  components={{ Option: CustomDrop }}
                />
                {erreurPseudoClient && (
                  <small className="text-danger">{erreurPseudoClient}</small>
                )}
              </div>
            )}
            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Sélection support</div>
              <ReactSelect
                options={optionsSupport}
                className={` rounded ${
                  erreurSupportClient ? "border border-danger" : ""
                }`}
                classNamePrefix="select"
                name="support"
                placeholder=""
                onChange={(selectedOption) => {
                  setSupportClient(selectedOption.value);
                }}
                components={{ Option: CustomDrop }}
              />
              {erreurSupportClient && (
                <small className="text-danger">{erreurSupportClient}</small>
              )}
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Prénom</div>
              <input
                type="text"
                onChange={(e) => setPrenomClient(e.target.value)}
                className={`form-control input-add ${
                  erreurPrenomClient ? "border-danger" : ""
                }`}
              />
              {erreurPrenomClient && (
                <small className="text-danger">{erreurPrenomClient}</small>
              )}
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Nom</div>
              <input
                type="text"
                onChange={(e) => setNomClient(e.target.value.trimStart())}
                className={`form-control input-add ${
                  erreurNomClient ? "border-danger" : ""
                }`}
              />
              {erreurNomClient && (
                <small className="text-danger">{erreurNomClient}</small>
              )}
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Customer ID</div>
              <input
                type="text"
                onChange={(e) => setCustomer_id(e.target.value.trimStart())}
                className={`form-control input-add ${
                  erreurCustIdClient ? "border-danger" : ""
                }`}
              />
              {erreurCustIdClient && (
                <small className="text-danger">{erreurCustIdClient}</small>
              )}
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Brand</div>
              <ReactSelect
                options={optionsBrands}
                className={`  rounded  ${
                  erreurBrandClient ? "border border-danger" : ""
                }`}
                classNamePrefix="select"
                name="brand"
                placeholder=""
                onChange={(selectedOption) =>
                  setBrandClient(selectedOption ? selectedOption.value : null)
                }
                isClearable={true}
              />
              {erreurBrandClient && (
                <small className="text-danger">{erreurBrandClient}</small>
              )}
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Montant €</div>
              <input
                type="number"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.includes(",") || value.includes(".")) {
                    setErrorVirgule(true);
                  } else {
                    setErrorVirgule(false);
                  }
                  setAmountClient(value);
                }}
                className={`form-control input-add ${
                  erreurAmountClient ? "border-danger" : ""
                }${errorVirgule ? "border-danger" : ""}`}
              />
              {errorVirgule && (
                <small className="text-danger">Uniquement un nombre</small>
              )}
              {erreurAmountClient && (
                <small className="text-danger">{erreurAmountClient}</small>
              )}
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Date d'échéance</div>
              <DatePicker
                className={` col-12 form-control p-2 rounded border input-add ${
                  erreurDateEcheance ? "border-danger" : ""
                }`}
                placeholderText=""
                selected={dateEcheance}
                onChange={(date) => setDateEcheance(date)}
              />
              <br />
              {erreurDateEcheance && (
                <small className="text-danger">{erreurDateEcheance}</small>
              )}
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Convertion</div>
              <div className="d-flex m-2 align-items-center">
                <div className="me-3 mt-2">NON / OUI</div>
                <Form.Check
                  type="switch"
                  className="custom-switch-drop ms-3"
                  style={{ transform: "scale(1.5)" }}
                  onChange={(e) => setIsConvertion(e.target.checked ? 1 : 0)}
                />
              </div>
            </div>

            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">🪪Envoyer le client à la verif ? 🕵️</div>
              <div className="d-flex m-2 align-items-center">
                <div className="me-3 mt-2">NON / OUI</div>
                <Form.Check
                  type="switch"
                  className="custom-switch-drop ms-3"
                  style={{ transform: "scale(1.5)" }}
                  onChange={(e) => setIsSendVerif(e.target.checked ? 1 : 0)}
                />
              </div>
            </div>

          </div>
          {profilToAdmin !== "sell" && profilToAdmin !== "tlead" && (
            <>
              <div>
                <div className="border my-2"></div>
                <div
                  style={{ textDecoration: "underline" }}
                  className="mb-3 fw-bold mt-1 text-black py-2 col-12"
                >
                  Gestion support
                </div>
                <div className="p-1 grid-container text-black">
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">PSP</div>
                    <ReactSelect
                      options={optionsPsps}
                      className="basic-single"
                      classNamePrefix="select"
                      name="brand"
                      placeholder=""
                      onChange={(selectedOption) =>
                        setPspClient(
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      isClearable={true}
                    />
                  </div>
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Plateforme</div>
                    <ReactSelect
                      options={optionsNeos}
                      className="basic-single"
                      classNamePrefix="select"
                      name="neo"
                      placeholder=""
                      onChange={(selectedOption) =>
                        setNeoClient(
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      isClearable={true}
                    />
                  </div>
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Argent disponible</div>
                    <div className="d-flex m-2">
                      <div className="me-3 mt-2">NON / OUI</div>
                      <Form.Check
                        type="switch"
                        className="custom-switch-drop"
                        style={{ transform: "scale(1.5)" }}
                        onChange={(e) =>
                          setArgent_dispoClient(e.target.checked ? 1 : 0)
                        }
                      />
                    </div>
                  </div>
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Banque client</div>
                    <ReactSelect
                      options={optionsBankClients}
                      className="basic-single"
                      classNamePrefix="select"
                      name="bank"
                      placeholder=""
                      onChange={(selectedOption) =>
                        setBankClient(
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      isClearable={true}
                    />
                  </div>
                </div>
                <div className="p-1 grid-container text-black">
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Preuve de virement</div>
                    <div className="d-flex m-2">
                      <div className="me-3 mt-2">NON / OUI</div>
                      <Form.Check
                        type="switch"
                        className="custom-switch-drop"
                        style={{ transform: "scale(1.5)" }}
                        onChange={(e) =>
                          setPreuveClient(e.target.checked ? 1 : 0)
                        }
                      />
                    </div>
                  </div>
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Date du virement</div>
                    <DatePicker
                      className="p-2 rounded border"
                      onChange={(date) => {
                        handleDate_vir(date);
                      }}
                      selected={date_virC}
                    />
                  </div>
                </div>
              </div>
              <div className="border my-2"></div>
              <div
                style={{ textDecoration: "underline" }}
                className="mb-3 fw-bold mt-1 text-black py-2"
              >
                Rappel
              </div>
              <div className="p-1 grid-container text-black">
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">Date de rappel</div>
                  <input
                    type="date"
                    name="date"
                    className="form-control"
                    onChange={(e) => setNext_callClient(e.target.value)}
                  />
                </div>
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">Heure de rappel</div>
                  <input
                    type="time"
                    name="time"
                    className="form-control"
                    onChange={(e) => setHoursClient(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          <div className="p-0 me-1 mt-4">
            <button
              type="submit"
              disabled={loaderButton}
              className="border btn btn-green col-12 text-black me-2"
            >
             {loaderButton ? 
                       <CircleLoader
                       color="black"
                       loading="true"
                       size={20}
                       aria-label="Loading Spinner"
                       data-testid="loader"
                     />
              :
              'Valider'} 
            </button>
            {error && (
              <smal className="text-danger">Une erreur est survenue</smal>
            )}
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddContrat;
