// DropCall.js
import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import avatar from "../../../images/avataaars (1).svg";
import { set } from "react-hook-form";
// import ConfettiExplosion from "react-confetti-explosion";

const Timer = () => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const formatTime = (seconds) => {
    const mins = String(Math.floor(seconds / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${mins}:${secs}`;
  };

  return <div>{formatTime(time)}</div>;
};

const DropCall = ({ data, handleClose }) => {
  // useEffect(() => {
  //     const synth = window.speechSynthesis;
  //     const voices = synth.getVoices();

  //     const sellerVoice = voices.find(voice => voice.name === 'Google français'); // Adjust voice name as needed
  //     const clientVoice = voices.find(voice => voice.name === 'Google UK English Male'); // Adjust voice name as needed

  //     const sellerUtterance = new SpeechSynthesisUtterance(`Bonjour, je suis votre conseiller en investissement. Comment puis-je vous aider aujourd'hui?`);
  //     sellerUtterance.voice = sellerVoice;

  //     const clientUtterance = new SpeechSynthesisUtterance(`oui , je veux recupérer mon cash .`);
  //     clientUtterance.voice = clientVoice;

  //     synth.speak(sellerUtterance);

  //     sellerUtterance.onend = () => {
  //       synth.speak(clientUtterance);
  //     };
  //   }, []);

  const [isEditingNote, setIsEditingNote] = useState(false); // Added state
  const [showMessageSent, setShowMessageSent] = useState(false);
  // const [explode, setExplode] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [messageSend, setMessageSend] = useState(false);
  const [showActualIP, setShowActualIP] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const textareaRef = useRef(null);
  const [buttonActionsSel, setButtonActionsSel] = useState(true);

  useEffect(() => {
    if (isEditingNote && textareaRef.current) {
      textareaRef.current.focus(); // Focus textarea when editing
    }
  }, [isEditingNote]);

  const toggleShowActualIP = () => {
    // console.log("icic");
    setShowActualIP(false);
    setShowHistory(true);
    setIsEditingNote(false);
    setMessageSend(false);
    setShowMessageSent(false);
    setButtonActionsSel(false);
  };

  const handleSend = () => {
    // Perform send action here (e.g., save the note)
    setShowActualIP(true);
    setMessageSend(true);
    setShowMessageSent(true);
    // setExplode(true);
    setTimeout(() => {
      setShowMessageSent(false);
      setIsEditingNote(false);
      // setExplode(false);
      setNoteText("");
    }, 1000);
  };

  const handleSpeak = () => {
    if (messageSend) {
      // setIsEditingNote(true);
      const synth = window.speechSynthesis;
      const utterThis = new SpeechSynthesisUtterance(`biiiip biiiiip bip!`);
      synth.speak(utterThis);
      setMessageSend(false);
      handleClose();
    } else {
      setIsEditingNote(true);
      // const synth = window.speechSynthesis;
      // const utterThis = new SpeechSynthesisUtterance(`biiiip biiiiip bip!`);
      // synth.speak(utterThis);
      // handleClose();
    }
  };

  const [callHistory, setCallHistory] = useState([
    {
      type: "create",
      old: "",
      new: "New lead",
      date: "13/12/12",
      time: "11:00",
      note: "",
    },
    {
      type: "status change",
      old: "En cours",
      new: "Terminé",
      date: "12/12/12",
      time: "10:15",
      note: "",
    },
    {
      type: "status change",
      old: "En cours",
      new: "Terminé",
      date: "12/12/12",
      time: "10:15",
      note: "",
    },
  ]);

  const showButtonAction = () => {
    setButtonActionsSel(true);
    setShowActualIP(true);
    setIsEditingNote(false);
    setMessageSend(false);
    setShowMessageSent(false);
    setShowHistory(false);
  }

  return (
    <>
      <div className="d-flex justify-content-end rounded-top"style={{ backgroundColor: "#f7f7f7" }}>
        <button
          className="btn text-end p-1 me-3 mt-1"
          onClick={() => {
            handleClose();
          }}
        >
          x
        </button>
      </div>
      {data &&
        [data]?.map((item) => (
          <div
            key={item.id}
            className="rounded-bottom d-flex flex-column justify-content-center"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <div className="bg-gradient-vert rounded text-white fw-bold m-2 p-1">
              <div
                className={`fs-16 ms-2 col-5 border border-white rounded-1 m-2 px-0 text-center actual-ip-container ${
                  showActualIP ? "show" : "hide"
                }`}
              >
                + {item.actual_client_ip}
              </div>
              <div className="d-flex align-items-center ms-2">
                <img
                  src={avatar}
                  alt=""
                  style={{ height: "40px", marginRight: "10px" }}
                  className={` actual-ip-container ${
                    showActualIP ? "show" : "hide"
                  }`}
                />
                <div className="fs-18 fw-bold">{item.full_name}</div>
              </div>
              <div
                className={`col-12 d-flex justify-content-between rounded fs-14 px-3 my-2 actual-ip-container ${
                  showActualIP ? "show" : "hide"
                }`}
              >
                <div className="d-flex ">
                  <div>
                    <img
                      src="https://flagcdn.com/16x12/us.png"
                      srcset="https://flagcdn.com/32x24/us.png 2x,
    https://flagcdn.com/48x36/us.png 3x"
                      width="16"
                      height="12"
                      alt="United States"
                    />
                  </div>
                  <div>+ {item.actual_client_ip}</div>
                </div>
                <Timer />
              </div>
              <div className="rounded bg-vertlight">
                <div className="rounded d-flex justify-content-center bg-white p-1">
                  <div onClick={() => showButtonAction()} className={`col-6 rounded text-center py-1 fs-16 fw-normal pointer ${buttonActionsSel ? ' bg-gardient-vertlight text-white' : 'text-black'}`}>
                    Actions
                  </div>
                  <div
                    onClick={() => toggleShowActualIP()}
                    className={`col-6 rounded text-center text-dark py-1 fs-16 fw-normal pointer ${!buttonActionsSel ? ' bg-gardient-vertlight text-white' : ''}`}
                  >
                    History
                  </div>
                </div>
                <div className="d-flex justify-content-around p-1">
                  <div className="d-flex flex-column align-items-center">
                    <div className="border-icon-call d-flex align-items-center justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="grey"
                        className="bi bi-telephone-forward-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </div>
                    <div className="text-dark fw-normal">transfert</div>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="border-icon-call d-flex align-items-center justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="grey"
                        className="bi bi-pause-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5m5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5" />
                      </svg>
                    </div>
                    <div className="text-dark fw-normal">hold</div>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center"
                    onClick={() => setIsEditingNote(!isEditingNote)}
                  >
                    <div className="border-icon-call d-flex align-items-center justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="grey"
                        className="bi bi-file-earmark-plus-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0" />
                      </svg>
                    </div>
                    <div className="text-dark fw-normal">note</div>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="border-icon-call d-flex align-items-center justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="34"
                        fill="grey"
                        className="bi bi-tag-fill"
                        viewBox="0 0 16 16"
                        style={{ transform: "rotate(90deg)" }}
                      >
                        <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                      </svg>
                    </div>
                    <div className="text-dark fw-normal">tags</div>
                  </div>
                </div>
              </div>
            </div>
            {!isEditingNote ? (
              <>
                {showHistory ? (
                  <div className="show-history">
                    <ul>
                      {callHistory.map((action, index) => (
                        <li key={index} className="card-history">
                          <div>
                            Type: <div>{action.type}</div>
                          </div>
                          <div>
                            Ancien Statut: <div>{action.old}</div>
                          </div>
                          <div>
                            Nouveau Statut: <div>{action.new}</div>
                          </div>
                          <p className="fs-12 d-flex justify-content-end">
                            {action.date} {action.time}
                          </p>
                          {/* <input
                            type="text"
                            value={action.note}
                            onChange={(e) => {
                              const updatedHistory = [...callHistory];
                              updatedHistory[index].note = e.target.value;
                              setCallHistory(updatedHistory);
                            }}
                            placeholder="Ajouter une note"
                          /> */}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="border m-2 p-2 bg-white rounded border-0">
                    <div className="d-flex justify-content-around rounded p-2">
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-mic-mute-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4 4 0 0 0 12 8V7a.5.5 0 0 1 1 0zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a5 5 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4m3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3" />
                            <path d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607m-7.84-9.253 12 12 .708-.708-12-12z" />
                          </svg>
                        </div>
                        <div className="text-dark fw-normal">mute</div>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="currentColor"
                            className="bi bi-grid-3x3-gap"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 2v2H2V2zm1 12v-2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m5 10v-2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1m0-5V2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1M9 2v2H7V2zm5 0v2h-2V2zM4 7v2H2V7zm5 0v2H7V7zm5 0h-2v2h2zM4 12v2H2v-2zm5 0v2H7v-2zm5 0v2h-2v-2zM12 1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm-1 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1z" />
                          </svg>
                        </div>
                        <div className="text-dark fw-normal">keypad</div>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="red"
                            className="bi bi-record-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                          </svg>
                        </div>
                        <div className="text-dark fw-normal">rec.</div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around rounded p-2">
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            fill="currentColor"
                            className="bi bi-plus-lg"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                            />
                          </svg>
                        </div>
                        <div className="text-dark fw-normal">call</div>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-share-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                          </svg>
                        </div>
                        <div className="text-dark fw-normal">share</div>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <div className="border-icon-call-2 d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            fill="currentColor"
                            className="bi bi-tablet"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                          </svg>
                        </div>
                        <div className="text-dark fw-normal">mobile</div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : showMessageSent ? (
              <>
                <div className="message-sent-container">
                  {/* {explode && (
                    <ConfettiExplosion
                      force={0.9}
                      duration={3000}
                      particleCount={250}
                      width={1400}
                    />
                  )} */}
                  <div className="message-sent">Message envoyé</div>
                </div>
              </>
            ) : (
              <>
                <textarea
                  ref={textareaRef}
                  className="form-control col-11 m-auto textearea-call mb-2"
                  rows="3"
                  placeholder="Une note doit être écrite pour chaque appel."
                  value={noteText}
                  onChange={(e) => setNoteText(e.target.value)}
                />
                <button
                  className="btn border-0 col-10 m-auto border-black p-1 bg-vertlight text-black fw-bold"
                  onClick={handleSend}
                  disabled={!noteText.trim()}
                >
                  Envoyer
                </button>
              </>
            )}
            <div className="m-2 p-2 d-flex justify-content-around align-items-end">
              <div className="btn-icon-call-bottom pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  className="bi bi-mic-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0z" />
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5" />
                </svg>
              </div>
              <div className="btn-icon-call-red pointer">
                <svg
                  onClick={handleSpeak}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="white"
                  className="bi bi-telephone-fill"
                  viewBox="0 0 16 16"
                  style={{ transform: "rotate(135deg)" }}
                >
                  <path
                    fillRule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                  />
                </svg>
              </div>
              <div className="btn-icon-call-bottom pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  fill="currentColor"
                  className="bi bi-volume-up"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.536 14.01A8.47 8.47 0 0 0 14.026 8a8.47 8.47 0 0 0-2.49-6.01l-.708.707A7.48 7.48 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303z" />
                  <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.48 5.48 0 0 1 11.025 8a5.48 5.48 0 0 1-1.61 3.89z" />
                  <path d="M10.025 8a4.5 4.5 0 0 1-1.318 3.182L8 10.475A3.5 3.5 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.5 4.5 0 0 1 10.025 8M7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11" />
                </svg>
              </div>
            </div>
          </div>
        ))}
      {/* <Button variant="primary" onClick={()=>handleClose()}>
        Close Modal
      </Button> */}
    </>
  );
};

export default DropCall;
