import React, { useEffect, useState, useMemo } from "react";
import AddLead from "./AddLead";
import UpdateLead from "./UpdateLead";
import {
  Button,
  Offcanvas,
  Table,
  Card,
  Spinner,
  Modal,
} from "react-bootstrap";
import ReactSelect from "react-select";
// import './pageleads.css'; // Assurez-vous d'importer le fichier CSS
import sortTable from "../../../utils/Table/sortTable";
import { useSelector, useDispatch } from "react-redux";
import TrTable from "./TrTable";
import { setAllLeads, getAllLeads } from "../../Redux/slices/actions.slice";
import { useParams } from "react-router-dom";
import useHeaderRest from "../../../utils/useHeader";
import config from "../../../config/config";
import axios from "axios";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import svgRefresh from "../../../images/cycle-svgrepo-com (1).svg";
import dataTableau from "./Data/DataTableau";
import DropCall from "./DropCall";
import "bootstrap/dist/css/bootstrap.min.css";
import avatar from "../../../images/avataaars (1).svg";

const actionOptions = [
  // { value: "", label: "" },
  { value: "status", label: "Change Status" },
  { value: "pool", label: "Change Pool" },
  { value: "agent", label: "Change Agent" },
];
            {/* change status
            change pool
            change agent  */}

// const objRowTable = {
//   idleads: {
//     type: "int",
//     key: "idleads",
//     etat: 3,
//   },
//   created_at: {
//     type: "date",
//     key: "created_at",
//     etat: 3,
//   },
//   idusers: {
//     type: "int",
//     key: "idusers",
//     etat: 3,
//   },
//   s1: {
//     type: "int",
//     key: "s1",
//     etat: 3,
//   },
//   s2: {
//     type: "int",
//     key: "s2",
//     etat: 3,
//   },
//   prenom: {
//     type: "string",
//     key: "prenom",
//     etat: 3,
//   },
//   nom: {
//     type: "string",
//     key: "nom",
//     etat: 3,
//   },
//   crm_id: {
//     type: "int",
//     key: "crm_id",
//     etat: 3,
//   },
// };

const objRowTable = {
  id: {
    type: "int",
    key: "idleads",
    etat: 3,
  },
  mtlogin: {
    type: "string",
    key: "mtlogin",
    etat: 3,
  },
  full_name: {
    type: "string",
    key: "full_name",
    etat: 3,
  },
  retentionagentname: {
    type: "string",
    key: "retentionagentname",
    etat: 3,
  },
  is_ftd: {
    type: "boolean",
    key: "is_ftd",
    etat: 3,
  },
  country: {
    type: "string",
    key: "country",
    etat: 3,
  },
  status: {
    type: "string",
    key: "status",
    etat: 3,
  },
  pool: {
    type: "string",
    key: "pool",
    etat: 3,
  },
  created_date: {
    type: "date",
    key: "created_date",
    etat: 3,
  },
  converted_date: {
    type: "date",
    key: "converted_date",
    etat: 3,
  },
  ftd_date: {
    type: "date",
    key: "ftd_date",
    etat: 3,
  },
  actual_client_ip: {
    type: "string",
    key: "actual_client_ip",
    etat: 3,
  },
  verified: {
    type: "string",
    key: "verified",
    etat: 3,
  },
  unique_id: {
    type: "string",
    key: "unique_id",
    etat: 3,
  },
  totaldepositamount: {
    type: "float",
    key: "totaldepositamount",
    etat: 3,
  },
  laststatusdate: {
    type: "date",
    key: "laststatusdate",
    etat: 3,
  },
  totalwithdrawalamount: {
    type: "float",
    key: "totalwithdrawalamount",
    etat: 3,
  },
  lastcalldate: {
    type: "date",
    key: "lastcalldate",
    etat: 3,
  },
  firstcalldate: {
    type: "date",
    key: "firstcalldate",
    etat: 3,
  },
  city: {
    type: "string",
    key: "city",
    etat: 3,
  },
  state: {
    type: "string",
    key: "state",
    etat: 3,
  },
  gender: {
    type: "string",
    key: "gender",
    etat: 3,
  },
  initial_martial_status: {
    type: "string",
    key: "initial_martial_status",
    etat: 3,
  },
  initial_experience: {
    type: "boolean",
    key: "initial_experience",
    etat: 3,
  },
  initial_expectations: {
    type: "string",
    key: "initial_expectations",
    etat: 3,
  },
  initial_bonus: {
    type: "string",
    key: "initial_bonus",
    etat: 3,
  },
  initial_experienced_on: {
    type: "string",
    key: "initial_experienced_on",
    etat: 3,
  },
  initial_experience_earn: {
    type: "boolean",
    key: "initial_experience_earn",
    etat: 3,
  },
  initial_how_to_contact: {
    type: "string",
    key: "initial_how_to_contact",
    etat: 3,
  },
  mt_last_online: {
    type: "string",
    key: "mt_last_online",
    etat: 3,
  },
  atompix_last_online: {
    type: "string",
    key: "atompix_last_online",
    etat: 3,
  },
  dialer_id: {
    type: "int",
    key: "dialer_id",
    etat: 3,
  },
  dialer_type: {
    type: "string",
    key: "dialer_type",
    etat: 3,
  },
};

const PageLeads = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [stateP, setstateP] = useState(objRowTable);

  const [isLoading, setIsLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const AdminDetail = useSelector(getAdminDetails);
  const deskAdmin = AdminDetail.desk;

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setSelectedLead(null);
    setShow(false);
  };
  const handleShow = (lead) => {
    const id = lead.id;
    if (selectedLeads.includes(id)) {
      setSelectedLead(lead);
    } else {
      setSelectedLead(lead);
    }
    setShow(true);
  };

  const dispatch = useDispatch();
  const AllLeads = useSelector(getAllLeads);

  const { desk } = useParams();
  const useHeader = useHeaderRest();

  const deskValue = desk !== undefined ? [desk] : deskAdmin;

  const getAll = () => {
    setIsLoading(true);
    axios
      .post(
        `${config.apiAdmin}/sadmin/leads/get_all`,
        {
          desk: deskValue,
        },
        useHeader
      )
      .then((response) => {
        dispatch(setAllLeads(dataTableau));
        setIsLoading(false);
      })
      .catch((error) => {
        dispatch(setAllLeads([]));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAll();
    if (AllLeads.length === 0) {
      dispatch(setAllLeads([]));
    }
  }, []);

  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setSelectedLead(null);
  };
  const handleShowUpdate = (lead) => {
    // console.log(lead)
    setSelectedLead(lead);
    setShowUpdate(true);
  };

  const handleActionChange = (option) => {
    if (option === null) {
      setSelectedAction(null);
      // Reset or update other states as needed when cleared
    } else {
      setSelectedAction(option);
      // Handle the selected action
    }
    // console.log(`Selected action: ${option.label}`);
    // Ajoutez votre logique d'action ici
  };

  const handleSelectRow = (id) => {
    if (selectedLeads.includes(id)) {
      setSelectedLeads(selectedLeads.filter((item) => item !== id));
    } else {
      setSelectedLeads([...selectedLeads, id]);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#10d078",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#10D078",
      },
      backgroundColor: "white",
      minHeight: "30px",
      height: "30px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "0px 5px",
      position: "relative",
      zIndex: 100,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#10D078",
      cursor: "pointer",
      textAlign: "center",
      width: "100%",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#10D078",
      textAlign: "center",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#10D078",
      backgroundColor: state.isSelected ? "#e6ffe6" : "white",
      "&:hover": {
        backgroundColor: "#e6ffe6",
        color: "#10D078",
      },
      cursor: "pointer",
      textAlign: "center",
      borderRadius: "5px",
      // lineHeight: "29px",
      // minHeight: "40px",
      // height: "40px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0 8px",
      color: "#10D078",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#10D078",
      ":hover": {
        color: "#007b55",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#10D078",
      ":hover": {
        backgroundColor: "#e6ffe6",
        color: "#007b55",
      },
    }),
  };
  // c'est notre devoir de dire la rumeur sur (la meuf )aharon a Jordan
  // apres lui il dit ou il dit pas
  const stateRow = (row, type) => {
    if (stateP[row].etat === 3) {
      setstateP({ ...objRowTable, [row]: { etat: 1 } });

      sortTable(row, type, AllLeads, 1, dispatch, setAllLeads);
    } else if (stateP[row].etat === 1) {
      setstateP({ ...objRowTable, [row]: { etat: 2 } });

      sortTable(row, type, AllLeads, 2, dispatch, setAllLeads);
    } else if (stateP[row].etat === 2) {
      setstateP({ ...objRowTable, [row]: { etat: 3 } });

      sortTable(row, type, AllLeads, 3, dispatch, setAllLeads);
    }
  };

  const filteredLeads = useMemo(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    if (!searchTerm.trim()) {
      return AllLeads;
    }
    return AllLeads.filter((lead) => {
      return (
        lead.prenom.toLowerCase().includes(lowerCaseSearchTerm) ||
        lead.nom.toLowerCase().includes(lowerCaseSearchTerm) ||
        lead.crm_id.toString().includes(lowerCaseSearchTerm)
      );
    });
  }, [AllLeads, searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusChange = (id, newStatus) => {
    console.log("Handle Status Change");
    // Mettre à jour l'état local ou via Redux
    // setStatusLeads((prevLeads) =>
    //   prevLeads.map((lead) =>
    //     lead.id === id ? { ...lead, status: newStatus } : lead
    //   )
    // );
  };

  return (
    <Card className="col-12 p-0">
      <Card.Header className="p-3">
        <div className="pageleads-search-input-container my-auto">
          <div className="pageleads-input-group">
            <input
              type="text"
              className="pageleads-form-control pageleads-rounded-start bg-grey-lead"
              // placeholder="Search..."
              aria-label="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
            <span className="pageleads-input-group-text pageleads-rounded-end pageleads-search-icon bg-grey-lead">
              <i className="bi bi-search"></i>
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
        <div
          className="refresh-lead pointer me-1"
          onClick={getAll}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >



            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="#10d078"
            className="bi bi-funnel pointer"
            viewBox="0 0 16 16"
          >
            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
          </svg>
          {/* )} */}
        </div>
        <div
          className="refresh-lead pointer me-1"
          onClick={getAll}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Spinner
              animation="border"
              size="sm"
              style={{ color: "#10d078" }}
            />
          ) : (
            <img src={svgRefresh} alt="" />
          )}
        </div>
          <ReactSelect
            value={selectedAction}
            onChange={handleActionChange}
            options={actionOptions}
            placeholder="Action"
            styles={customStyles}
            className="me-1"
            menuPosition="fixed"
            menuPortalTarget={document.body}
            isClearable
          />

          <button
            className="border btn  text-black h-50 me-2 py-1"
            onClick={handleShowAdd}
            style={{
              borderRadius: "5px",
              fontSize: "14px",
              backgroundColor: "#10d078",
            }}
          >
            Create Lead
          </button>
        </div>
      </Card.Header>
      <Card.Body className="table-responsive p-0">
        <Table striped hover className=" p-0">
          <thead>
            {stateP && <TrTable stateP={stateP} stateRow={stateRow} />}
          </thead>
          <tbody>
            {filteredLeads?.map((lead) => (
              <tr
                key={lead.idleads}
                style={{ cursor: "pointer" }}
                onClick={() => handleShowUpdate(lead)}
              >
                <td onClick={(e) => e.stopPropagation()}>
                  <div
                    className={`custom-checkbox-container ${
                      selectedLeads.includes(lead.id) ? "checked" : ""
                    }`}
                    onClick={() => handleSelectRow(lead.id)}
                  >
                    <div
                      className={`custom-checkbox ${
                        selectedLeads.includes(lead.id) ? "checked" : ""
                      }`}
                    ></div>
                  </div>
                </td>
                <td>{lead.id}</td>
                <td onClick={(e) => e.stopPropagation()}>
                  <svg
                    onClick={() => handleShow(lead)}
                    style={{ cursor: "pointer" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#10d078"
                    className="bi bi-telephone-fill zoom-icon"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                    />
                  </svg>
                </td>
                <td>{lead.mtlogin}</td>
                <td>{lead.full_name}</td>
                <td>
                  <div className="center-flex">
                    <img src={avatar} alt="" style={{ height: "24px" }} />
                    {lead.retentionagentname}
                  </div>
                </td>
                <td>{lead.is_ftd ? "Yes" : "No"}</td>
                <td>{lead.country}</td>
                <td onClick={(e) => e.stopPropagation()}>
                  <select
                    value={lead.status}
                    onChange={(e) =>
                      handleStatusChange(lead.id, e.target.value)
                    }
                    className="status-dropdown pointer"
                  >
                    <option value="Active">Active</option>
                    <option value="Pending">Pending</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Call again">Call again</option>
                  </select>
                </td>
                <td>{lead.pool}</td>
                <td>{new Date(lead.created_date).toLocaleString()}</td>
                <td>{new Date(lead.converted_date).toLocaleString()}</td>
                <td>{new Date(lead.ftd_date).toLocaleString()}</td>
                <td>{lead.actual_client_ip}</td>
                <td>{lead.verified}</td>
                <td>{lead.unique_id}</td>
                <td>{lead.totaldepositamount}</td>
                <td>{new Date(lead.laststatusdate).toLocaleString()}</td>
                <td>{lead.totalwithdrawalamount}</td>
                <td>{lead.lastcalldate || "N/A"}</td>
                <td>{lead.firstcalldate || "N/A"}</td>
                <td>{lead.city}</td>
                <td>{lead.state}</td>
                <td>{lead.gender || "N/A"}</td>
                <td>{lead.initial_martial_status || "N/A"}</td>
                <td>{lead.initial_experience ? "Yes" : "No"}</td>
                <td>{lead.initial_expectations || "N/A"}</td>
                <td>{lead.initial_bonus || "N/A"}</td>
                <td>{lead.initial_experienced_on || "N/A"}</td>
                <td>{lead.initial_experience_earn ? "Yes" : "No"}</td>
                <td>{lead.initial_how_to_contact || "N/A"}</td>
                <td>{lead.mt_last_online}</td>
                <td>{lead.atompix_last_online || "N/A"}</td>
                <td>{lead.dialer_id}</td>
                <td>{lead.dialer_type || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="custom-modal"
        centered
        size="sm"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-0" style={{ width: "297px" }}>
          <DropCall data={selectedLead} handleClose={() => handleClose()} />
        </Modal.Body>
      </Modal>

      <Offcanvas show={showAdd} onHide={handleCloseAdd} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ajouter Lead</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddLead />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showUpdate}
        onHide={handleCloseUpdate}
        data={selectedLead}
        placement="end"
        style={{ width: "40%" }}
      >
        {selectedLead && <UpdateLead lead={selectedLead} />}
      </Offcanvas>
    </Card>
  );
};

export default PageLeads;
