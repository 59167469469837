import React, { useState } from "react";
import { Form, Button, Table, Modal } from "react-bootstrap";

const TrandingAccountsLead = () => {
  const dataToShow = {
    ID: "49380",
    Login: "49467320290",
    Type: "Live",
    "MT Group": "liv/myacc/FTRLYTYPE1",
    Currency: "EUR",
    Leverage: "0",
    Withdrawal: "0",
    Deposit: "1.05",
    Balance: "1",
    "FTD Date": "January 1st 0001, 2:20:54 am",
    "Created Date": "December 11th 2024, 3:14:18 pm",
  };

  const [formData, setFormData] = useState(dataToShow);
  const [leadToSend, setLeadToSend] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLeadToSend(formData);
    setShowModal(false);
  };

  const handleRowClick = () => {
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="col-12">
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Table striped hover className="p-0 mb-4">
          <thead>
            <tr>
              {Object.keys(dataToShow).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr onClick={handleRowClick} style={{ cursor: "pointer" }}>
              {Object.values(dataToShow).map((value, index) => (
                <td key={index}>{value}</td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier les Détails du Compte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className="grid-container text-black">
            {Object.keys(dataToShow).map((key) => (
              <div className="grid-item p-0 me-1" key={key}>
                <div className="mx-2 mb-1">{key}</div>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={formData[key]}
                  onChange={(e) => handleChange(key, e.target.value)}
                  readOnly={key === "ID"}
                />
              </div>
            ))}
            <div className="grid-item p-0 me-1 full-width mt-2">
              <Button
                type="submit"
                className="border pointer btn btn-green text-black me-2 col-12"
              >
                Valider
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {leadToSend && (
        <div className="mt-4">
          <h6>Objet à envoyer:</h6>
          <pre>{JSON.stringify(leadToSend, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default TrandingAccountsLead;
