import React, { useState } from "react";
import { Form, Button, Table, Modal } from "react-bootstrap";
import ReactSelect from "react-select";

const BankDetailLead = () => {
  const dataToShow = {
    ID: "12345",
    BeneficiaryName: "John Doe",
    SocialSecurityNumber: "123-45-6789",
    BankName: "First National Bank",
    AccountNumber: "987654321",
    SWIFT: "FNBGUS44XXX",
    IBAN: "GB29NWBK60161331926819",
    BeneficiaryAddress: "123 Main Street, New York, NY 10001",
    WalletAddress: "0x4E9ce36E442e55EcD9025B9a6E0D88485d628A67",
  };

  const [formData, setFormData] = useState(dataToShow);
  const [leadToSend, setLeadToSend] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const sOptions = []; // Define your options here
  const customStyles = {}; // Define your styles here

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLeadToSend(formData);
    setShowModal(false);
  };

  const handleRowClick = (rowData) => {
    setFormData(rowData);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="col-12">
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Table
          striped
          hover
          className="p-0 mb-4"
        >
          <thead>
            <tr>
              {Object.keys(dataToShow).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr
              onClick={() => handleRowClick(dataToShow)}
              style={{ cursor: "pointer" }}
            >
              {Object.values(dataToShow).map((value, index) => (
                <td key={index}>{value}</td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier les Détails Bancaires</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className="grid-container text-black">
            {Object.keys(dataToShow).map((key) => (
              <div className="grid-item p-0 me-1" key={key}>
                <div className="mx-2 mb-1">{key}</div>
                {key.includes("S") ? (
                  <ReactSelect
                    value={formData[key]}
                    onChange={(selected) => handleChange(key, selected)}
                    options={sOptions}
                    styles={customStyles}
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    required
                    value={formData[key]}
                    onChange={(e) => handleChange(key, e.target.value)}
                    readOnly={key === "ID"}
                  />
                )}
              </div>
            ))}
            <div className="grid-item p-0 me-1 full-width mt-2">
              <Button
                type="submit"
                className="border pointer btn btn-green text-black me-2 col-12"
              >
                Valider
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {leadToSend && (
        <div className="mt-4">
          <h6>Objet à envoyer:</h6>
          <pre>{JSON.stringify(leadToSend, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default BankDetailLead;
