import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { getAdminDetails, getAdminDesks, getToken } from "../../Redux/slices/admin.slice";
import Cookies from 'js-cookie';
import localforage from 'localforage';
import config from "../../../config/config"

const PageDoc = () => {
  const AdminDetails = useSelector(getAdminDetails);
  const AdminDesks = useSelector(getAdminDesks);
  const AdminToken = useSelector(getToken);
  const [openIframe, setOpenIframe] = useState({ open: false, url: "" });

  useEffect(() => {
    console.log("Ouverture des docs", AdminDetails);
    getTokenOauth();
  }, []);

  const getTokenOauth = async () => {
    try {
      const timestamp = Date.now();

      const urlOriginTrello = config.urlTrello  ;
      if(urlOriginTrello === "") {
        return 
      }

      // Première requête pour obtenir le token
      const resOauth = await axios.get(`${urlOriginTrello}/api/v1/oauth.json?_=${timestamp}`);
      // console.log("resOauth.data", resOauth.data);

      const accessToken = resOauth.data.access_token;

      // Deuxième requête pour se connecter
      const resLogin = await axios.post(
        `${urlOriginTrello}/api/v1/users/login.json?token=${accessToken}`,
        {
          email: AdminDetails.pseudo,
          password: AdminDetails.idusers,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log("resLogin.data", resLogin.data);
      const { access_token, refresh_token, user } = resLogin.data;
      const { id, is_productivity_beats, initials, profile_picture_path, role_id,
        username, full_name, persist_card_divider_position, timezone,
        notify_count, last_activity_id, language, default_desktop_notification,
        is_list_notifications_enabled, is_card_notifications_enabled,
        is_card_members_notifications_enabled,
        is_card_labels_notifications_enabled,
        is_card_checklists_notifications_enabled,
        is_card_attachments_notifications_enabled,
        is_ldap, is_saml, is_intro_video_skipped,
        next_community_edition_popup_on, is_show_community_edition_popup,
        is_two_factor_authentication_enabled
      } = user;

      const filteredData = {
        access_token,
        refresh_token,
        user: {
          id,
          username, 
          is_productivity_beats, initials, profile_picture_path, role_id, 
          full_name, persist_card_divider_position, timezone, notify_count,
          last_activity_id, language, default_desktop_notification,
          is_list_notifications_enabled, is_card_notifications_enabled,
          is_card_members_notifications_enabled, is_card_labels_notifications_enabled,
          is_card_checklists_notifications_enabled, is_card_attachments_notifications_enabled,
          is_ldap, is_saml, is_intro_video_skipped, next_community_edition_popup_on,
          is_show_community_edition_popup, is_two_factor_authentication_enabled

        }
      };
      const encodedString = JSON.stringify(filteredData);
      // console.log("encodedString", encodedString);

      Cookies.set('auth', encodedString, { path: '/', domain: config.baseFront });
      Cookies.remove('push_tokens', { path: '/', domain: config.baseFront });

      // 5. Parser 'response.links'
      let links = {};
      if (resLogin.data.links) {
        try {
          links = JSON.parse(resLogin.data.links);
           await localforage.setItem("links", links.links);
          //  console.log("l'action du localforage est terminee", resLogin.data.links)
        } catch (e) {
          console.error("Erreur lors du parsing de 'auth_response.links' :", e);
        }
      }

      const getAllDesksUsers = await axios.post(
        `${config.apiAdmin}/sadmin/users/UsersByDesksArrayCategories`,
        {
          desk: AdminDesks,
          categories: "SELLER"
      },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AdminToken}`
          },
        }
      );
      // on fait cela pour  transformer un objet en Array
      const pseudos = getAllDesksUsers.data.map(item => item.pseudo);

      
        // cas classique d'un manager classique
        const arrayToStore = pseudos;
        const arrayString = JSON.stringify(arrayToStore);
        Cookies.set('srcgoogle', arrayString, { domain: config.baseFront });     
      
      
      setOpenIframe(prevState => ({
        url: `${urlOriginTrello}/#/boards`, // pour afficher tous les boards cas des managers
        open: true
      }));
    } catch (error) {
      console.error("Erreur lors des appels API :", error);
    }
  };

  return (
    <>
      {openIframe.open && 
        <iframe src={openIframe.url} width="100%" height="1000px"></iframe>
      }
    </>
  );
};

export default PageDoc;