const objRowTable = {
  idleads: {
    type: "int",
    key: "idleads",
    etat: 3,
  },
  mtlogin: {
    type: "string",
    key: "mtlogin",
    etat: 3,
  },
  full_name: {
    type: "string",
    key: "full_name",
    etat: 3,
  },
  retentionagentname: {
    type: "string",
    key: "retentionagentname",
    etat: 3,
  },
  is_ftd: {
    type: "boolean",
    key: "is_ftd",
    etat: 3,
  },
  country: {
    type: "string",
    key: "country",
    etat: 3,
  },
  status: {
    type: "string",
    key: "status",
    etat: 3,
  },
  pool: {
    type: "string",
    key: "pool",
    etat: 3,
  },
  created_date: {
    type: "date",
    key: "created_date",
    etat: 3,
  },
  converted_date: {
    type: "date",
    key: "converted_date",
    etat: 3,
  },
  ftd_date: {
    type: "date",
    key: "ftd_date",
    etat: 3,
  },
  actual_client_ip: {
    type: "string",
    key: "actual_client_ip",
    etat: 3,
  },
  verified: {
    type: "string",
    key: "verified",
    etat: 3,
  },
  unique_id: {
    type: "string",
    key: "unique_id",
    etat: 3,
  },
  totaldepositamount: {
    type: "float",
    key: "totaldepositamount",
    etat: 3,
  },
  laststatusdate: {
    type: "date",
    key: "laststatusdate",
    etat: 3,
  },
  totalwithdrawalamount: {
    type: "float",
    key: "totalwithdrawalamount",
    etat: 3,
  },
  lastcalldate: {
    type: "date",
    key: "lastcalldate",
    etat: 3,
  },
  firstcalldate: {
    type: "date",
    key: "firstcalldate",
    etat: 3,
  },
  city: {
    type: "string",
    key: "city",
    etat: 3,
  },
  state: {
    type: "string",
    key: "state",
    etat: 3,
  },
  gender: {
    type: "string",
    key: "gender",
    etat: 3,
  },
  initial_martial_status: {
    type: "string",
    key: "initial_martial_status",
    etat: 3,
  },
  initial_experience: {
    type: "boolean",
    key: "initial_experience",
    etat: 3,
  },
  initial_expectations: {
    type: "string",
    key: "initial_expectations",
    etat: 3,
  },
  initial_bonus: {
    type: "string",
    key: "initial_bonus",
    etat: 3,
  },
  initial_experienced_on: {
    type: "string",
    key: "initial_experienced_on",
    etat: 3,
  },
  initial_experience_earn: {
    type: "boolean",
    key: "initial_experience_earn",
    etat: 3,
  },
  initial_how_to_contact: {
    type: "string",
    key: "initial_how_to_contact",
    etat: 3,
  },
  mt_last_online: {
    type: "string",
    key: "mt_last_online",
    etat: 3,
  },
  atompix_last_online: {
    type: "string",
    key: "atompix_last_online",
    etat: 3,
  },
  dialer_id: {
    type: "int",
    key: "dialer_id",
    etat: 3,
  },
  dialer_type: {
    type: "string",
    key: "dialer_type",
    etat: 3,
  },
};

export default objRowTable;
