import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useQuery, gql } from "@apollo/client";
import { IMAGES } from "../../../jsx/constant/theme";
import GrowthDounut from "./GrowthDounut";
import ExpensesBarChart from "./ExpensesBarChart";
import NewExpChart from "./NewExpChart";
import ImpressionBar from "./ImpressionBar";
import TableauConvertion from "./TableauConvertionSell";
import GraphRatioByAgent from "./GraphRatioByAgent2";
import avatar from "../../../images/avataaars.svg";
import avatar1 from "../../../images/avataaars (1).svg";
import avatar2 from "../../../images/avataaars (2).svg";
import FondPodiumOk from "../../../images/photo_2024-04-30_18-39-37.jpg";
import {
  getToken,
  getPseudo,
  getIdUsers,
} from "../../Redux/slices/admin.slice";
import { getSockets } from "../../Redux/slices/notifSocket.slice";
import formatNumber from "../../../utils/formatNumber";
import config from "../../../config/config";
import useHeaderRest from "../../../utils/useHeader";
import ProgressBarSeller from "../../Manager/Graph/ProgressBarSeller";
import ProgressBarMagic from "../../Global/ProgressBarMagic";
import GraphLastContractsByDay from "../../Manager/Graph/GraphLastContractsByDay";
import CircleOpacityAnimation from "./CircleOpacityAnimation";
import Component from "./ProgressBarSeller";
import GraphAverageRetap from "../../Manager/Graph/GraphAverageRetap";
import GraphPesAverageRetap from "../../Manager/Graph/GraphPesAverageRetap";

const PERFORMANCE_REPORT_BY_DESK = gql`
  query PerformanceReportByDesk {
    performanceReportByDesk {
      idusers
      number_of_conversions
      position
      pseudo
      sum_cash
      sum_target
      total_amount
      total_amount_encaisse
    }
  }
`;

const CardsSeller = () => {
  const Header = useHeaderRest();
  const GET_TOKEN = useSelector(getToken);
  const GET_SOCKETS = useSelector(getSockets);
  const GET_PSEUDO = useSelector(getPseudo);
  const GET_IDUSERS = useSelector(getIdUsers);
  const goodDesk = useSelector((state) => state.admin.desks[0]);
  const [arrSalaire, setArraySalaire] = useState([]);
  const [dataRatioAgent, setDataRatioAgent] = useState();
  const [findUserOnRation, setFindUserOnRation] = useState();
  const [TargetsPerso, setTargetsPerso] = useState([]);
  const [TargetsDesk, setTargetsDesk] = useState();

  const [dataBrutNetPrev, setDataBrutNetPrev] = useState("");
  const [dataRetraitSheet, setDataRetraitSheet] = useState("");
  const [dataTargetByDesk, setDataTargetByDesk] = useState();

  const [lastContractsByDay, setLastContractsByDay] = useState();
  const [averageRetap, setAverageRetap] = useState();

  const HelpRequest = {
    context: {
      headers: {
        Authorization: `Bearer ${GET_TOKEN}`,
      },
    },
  };

  const { data: dataPerformanceReport, refetch: refetchPerformanceReport } =
    useQuery(PERFORMANCE_REPORT_BY_DESK, HelpRequest);

  const DataRatioAgent = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_ration_agent`,
        { desk: goodDesk },
        Header
      )
      .then((response) => {
        setDataRatioAgent(response?.data?.data);
        setFindUserOnRation(
          (response?.data?.data).find((user) => user?.pseudo === GET_PSEUDO)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DataTargets = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/seller/target_perso`,
        { userId: GET_IDUSERS },
        Header
      )
      .then((response) => {
        console.log(response?.data?.data);
        setTargetsPerso(response?.data?.data);
        console.log("ici");
      })
      .catch((error) => {
        console.log("la");
        setTargetsPerso([{ objectif: 0, gift: 0, cash: 0 }]);
      });
    axios
      .post(
        `${config.apiAdmin}/statsNode/seller/target_desk`,
        { desk: goodDesk },
        Header
      )
      .then((response) => {
        console.log(response?.data?.data);
        setTargetsDesk(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const retraitSheet = () => {
    console.log("goodDesk", goodDesk);
    axios
      .post(
        `${config.apiAdmin}/sadmin/whsheet/sum_retrait`,
        { desk: goodDesk },
        Header
      )
      .then((response) => {
        console.log("response?.data =====>", response?.data);
        setDataRetraitSheet(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const brutnetPrev = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/brut_net_prev`,
        { desk: goodDesk },
        Header
      )
      .then((response) => {
        console.log(response?.data?.data);
        setDataBrutNetPrev(response?.data?.data);
        // const newValue = response.data.data.brutNetPrev.brut_previsionnel;
        // setPreviousValue(currentValue);
        // setCurrentValue(newValue);
        // setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (findUserOnRation) {
      setArraySalaire(() => [0, findUserOnRation.com_chiffre || 0]);
    }
  }, [findUserOnRation]);

  console.log(
    "dataRatioAgent?.map((agent) => agent?.sum_encashed",
    dataRatioAgent
      ?.map((agent) => parseFloat(agent?.sum_encashed) || 0)
      .reduce((acc, curr) => acc + curr, 0)
  );

  const GraphLastcontratByday = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/graph_last_contrat_by_day`,
        { desk: [goodDesk] },
        Header
      )
      .then((response) => {
        // console.log(
        //   // "graph_average_by_agent response?.data =====>",
        //   response?.data.data
        // );
        setLastContractsByDay(response?.data.data);
        // setDataGraphEncashed(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AverageRetap = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/graph_average_retap`,
        { desk: goodDesk },
        Header
      )
      .then((response) => {
        console.log(
          // "graph_average_by_agent response?.data =====>",
          response?.data.data
        );
        setAverageRetap(response?.data.data);
        // setDataGraphEncashed(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    DataRatioAgent();
    refetchPerformanceReport();
    DataTargets();
    brutnetPrev();
    retraitSheet();
    GraphLastcontratByday();
    AverageRetap();
    console.log("findUserOnRation", findUserOnRation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS]);

  const totalSumEncashed = dataRatioAgent?.reduce((total, user) => {
    return total + parseFloat(user.sum_encashed);
  }, 0);

  return (
    <>
      <div className="row ">
        {dataRetraitSheet && dataBrutNetPrev && TargetsDesk && (
          <ProgressBarSeller
            data={TargetsDesk || ["0"]}
            totalencaisse={
              Number(dataBrutNetPrev?.brutNetPrev.brut_previsionnel) +
                Number(dataRetraitSheet[0]?.total_amount) ||
              "0" ||
              "0"
            }
            totalnetprev={
              Number(dataBrutNetPrev?.brutNetPrev?.net_previsionnel || 0) +
                Number(dataRetraitSheet[0]?.total_amount) || 0
            }
          />
          // <ProgressBarMagic targets={["125000","250000","500000"]} data={["45000","5000"]} color={["#10d078","#ffd558"]}/>
          // <ProgressBarMagic targets={["125000","250000","500000"]} data={["45000","5000"]} color={["#10d078","#ffd558"]}/>
        )}
        {/* {TargetsDesk  && (<ProgressBarSeller data={{ res: TargetsDesk, sum: totalSumEncashed}}/>)} */}
        <div className="col-xl-3 col-xxl-3 col-sm-6 ">
          <div className="card overflow-hidden">
            <div className="card-body p-0">
              {/* <div className="c-con">
                <h4 className="card-title mb-0">Targets desk</h4>
              </div> */}
              <CircleOpacityAnimation findUserOnRation={findUserOnRation} />
            </div>
          </div>
        </div>
        {/* Moyenne chiffre agent signer */}
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card crm-cart ">
            <div className="card-body h-100">
              <div className="crm-cart-data">
                <h5 className="d-block mb-1 text-black">Total reporté</h5>
                <p>
                  {formatNumber(
                    (findUserOnRation && findUserOnRation?.reported_eur) || 0
                  )}{" "}
                  €
                </p>
              </div>
            </div>
            {/* <NewExpChart color={colorMap['green']}/> */}
            <NewExpChart color={"green"} />
          </div>
        </div>
        {/* Moyenne chiffre agent encaisser */}
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card crm-cart">
            <div className="card-body">
              <div className="crm-cart-data">
                <h5 className="d-block mb-1 text-black">Total (en preuve)</h5>
                <p>
                  {formatNumber(
                    findUserOnRation && findUserOnRation?.sum_proof_eur
                  )}{" "}
                  €
                </p>
              </div>
            </div>
            {/* <NewExpChart color={colorMap['blue']}/> */}
            <NewExpChart color={"blue"} />
          </div>
        </div>
        {/* Podium fait*/}
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div
            className="card crm-cart"
            style={{
              backgroundImage: `url(${FondPodiumOk})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              className="d-flex text-white fs-5 justify-content-around align-items-end mx-5 px-2"
              style={{ height: "65%" }}
            >
              {dataRatioAgent && (
                <>
                  <div
                    className=""
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={dataRatioAgent[1]?.avatar || avatar1}
                      title={dataRatioAgent[1]?.pseudo}
                      alt=""
                      style={{
                        height: "70px",
                        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                        borderRadius: "50%",
                      }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "black", // Couleur du texte
                        fontSize: "14px", // Taille du texte
                        fontWeight: "bold",
                        textAlign: "center",
                        width: "100%", // Pour s'assurer que le texte est centré
                        fontFamily: "Bangers, cursive",
                        letterSpacing: "1.5px",
                        backgroundColor: "white",
                        borderRadius: "50px",
                        border: "1px solid grey",
                      }}
                    >
                      {dataRatioAgent[1]?.pseudo}
                    </span>
                  </div>

                  <div className="mb-3">
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={dataRatioAgent[0]?.avatar || avatar}
                        title={dataRatioAgent[0]?.pseudo}
                        alt=""
                        style={{
                          height: "90px",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                          borderRadius: "50%",
                        }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "black", // Couleur du texte
                          fontSize: "16px", // Taille du texte
                          textAlign: "center",
                          fontWeight: "bold",
                          width: "100px", // Pour s'assurer que le texte est centré
                          fontFamily: "Bangers, cursive",
                          letterSpacing: "1.5px",
                          backgroundColor: "white",
                          borderRadius: "50px",
                          border: "1px solid grey",
                          zIndex: "9999",
                        }}
                      >
                        {dataRatioAgent[0]?.pseudo}
                      </span>
                    </div>
                  </div>

                  <div className="">
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={dataRatioAgent[2]?.avatar || avatar2}
                        title={dataRatioAgent[2]?.pseudo}
                        alt=""
                        style={{
                          height: "60px",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                          borderRadius: "50%",
                        }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "black",
                          fontSize: "14px",
                          textAlign: "center",
                          fontWeight: "bold",
                          padding: "0px 5px 0px 5px",
                          fontFamily: "Bangers, cursive",
                          letterSpacing: "1.5px",
                          backgroundColor: "white",
                          borderRadius: "50px",
                          border: "1px solid grey",
                        }}
                      >
                        {dataRatioAgent[2]?.pseudo}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {/* targets Agents */}
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card overflow-hidden">
            <div className="card-body p-1">
              {/* <div className="c-con">
                <h4 className="card-title mb-0">Targets agent</h4>
              </div> */}

              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0 text-black fw-bold"></h5>

                <div
                  className="text-end fs-2 fw-bold"
                  style={{ color: "#FFD700" }}
                >
                  {/* <img src={avatar3} alt="" style={{ height: "70px" }} />{dataRatioAgent?.find((perso)=> perso?.pseudo === GET_PSEUDO)?.idx} € */}
                  {/* <sup>e</sup> */}
                </div>
              </div>
              <div className="row">
                {TargetsPerso && TargetsPerso.length > 0 ? (
                  <Component
                    TargetsPerso={TargetsPerso}
                    findUserOnRation={findUserOnRation}
                  />
                ) : (
                  <Component
                    TargetsPerso={TargetsPerso}
                    findUserOnRation={findUserOnRation}
                  />
                )}
                {/* {TargetsPerso ? (
                  TargetsPerso?.map((item) => {
                    return (
                        // <div className="col-4 p-0" key={item.idtargets}>
                        //   <p className="text-center m-auto">
                        //     {item.objectif}${" "}
                        //   </p>
                        //   <div
                        //     className="progress border"
                        //     style={{ height: "25px" }}
                        //   >
                        //     <div
                        //       className="progress-bar bg-red h-100 fs-16"
                        //       style={{
                        //         width: `${
                        //           ((findUserOnRation &&
                        //             (findUserOnRation?.sum_encashed_eur || 0)) *
                        //             100) /
                        //           item.objectif
                        //         }%`,
                        //         height: "20px",
                        //         borderRadius: "8px",
                        //       }}
                        //     >
                        //       {Math.round(
                        //         ((findUserOnRation &&
                        //           (findUserOnRation?.sum_encashed_eur || 0)) *
                        //           100) /
                        //           item.objectif
                        //       ) >= 100
                        //         ? 100
                        //         : Math.round(
                        //             ((findUserOnRation &&
                        //               (findUserOnRation?.sum_encashed_eur ||
                        //                 0)) *
                        //               100) /
                        //               item.objectif
                        //           )}
                        //       %
                        //     </div>
                        //   </div>
                        //   <br />
                        //   <p className="text-center m-auto">
                        //     Pour {item.cash}$
                        //   </p>
                        // </div>
<Component/>
                    );
                  })
                ) : (
                  <div className="col-12 ">
                    <div
                      className="row align-items-center justify-content-center"
                      style={{ height: "10vh" }}
                    >
                      <div className="col-auto ">
                        <div className="text-center">
                          Pas de target personnelle
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
        {/* Total Signer */}
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card crm-cart">
            <div className="card-header border-0 pb-0 diposit-bg">
              <h5 className="d-block text-black">Total (Signé)</h5>
              {/* <div className="icon-box">{SVGICON.DollerBlack}</div> */}
            </div>
            <div className="card-body py-0">
              <div className="crm-cart-data d-flex align-items-center">
                <p>
                  {formatNumber(
                    findUserOnRation && (findUserOnRation?.totalSigned_eur || 0)
                  )}{" "}
                  €
                </p>
                {/* <span className="text-green fs-20 fw-bold">(+ 38%)</span> */}
              </div>
              <ImpressionBar />
            </div>
          </div>
        </div>
        {/* Total Encaisser */}
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card crm-cart">
            <div className="card-header border-0 pb-0 diposit-bg">
              <h5 className="d-block text-black">Total (encaissé)</h5>
              {/* <div className="icon-box">{SVGICON.DollerBlack}</div> */}
            </div>
            <div className="card-body py-0">
              <div className="crm-cart-data d-flex align-items-center">
                <p>
                  {formatNumber(
                    findUserOnRation &&
                      (findUserOnRation?.sum_encashed_eur || 0)
                  )}{" "}
                  €
                </p>
                {/* <span className="text-red fs-20 fw-bold">(- 9%)</span> */}
              </div>
              <ExpensesBarChart />
            </div>
          </div>
        </div>
        {/* Bonus previsionnel */}
        <div className="col-xl-3 col-xxl-3 col-sm-6">
          <div className="card crm-cart">
            <div className="card-header border-0 py-0">
              <div className="d-flex align-items-center justify-content-start col-12">
                <h5 className="mb-0 m-2 text-black">Bonus previsionnel</h5>
              </div>
            </div>
            <div className="card-body d-flex justify-content-center pt-2 text-black">
              {findUserOnRation && arrSalaire.length > 0 && (
                <GrowthDounut
                  arrSerie={arrSalaire}
                  arrLabel={["Target", "Commissions Sur Chiffre"]}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div style={{ height: "400px" }} className="col-9">
          <Card>
            <Card.Header>
              <div className="card-title col-12 ">
                <div className="d-flex align-iterms-center justify-content-between">
                  <div className="text-start col-9 text-start">
                    Ratio (signé / encaissé)
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {dataRatioAgent && TargetsPerso.length > 0 && (
                <GraphRatioByAgent
                  height={"300px"}
                  arrEncaisse={dataRatioAgent?.map(
                    (agent) => agent?.sum_encashed
                  )}
                  arrSigned={dataRatioAgent?.map((agent) => agent?.totalSigned)}
                  arrProof={dataRatioAgent?.map((agent) => agent?.sum_proof)}
                  arrReported={dataRatioAgent?.map((agent) => agent?.reported)}
                  arrCanceled={dataRatioAgent?.map((agent) => agent?.canceled)}
                  arrCategories={dataRatioAgent?.map((agent) => agent?.pseudo)}
                  arrtotal_esperance={dataRatioAgent?.map(
                    (agent) => agent?.total_esperance
                  )}
                  TargetsPerso={TargetsPerso}
                />
              )}
            </Card.Body>
          </Card>
        </div>
        <div className="col-3">
          <Card>
            <Card.Header className="fw-bold text-black fs-4">
              Nb de converstions
            </Card.Header>
            {dataPerformanceReport?.performanceReportByDesk && (
              <TableauConvertion
                arrCategories={dataPerformanceReport?.performanceReportByDesk?.map(
                  (user) => user?.pseudo
                )}
                data={dataPerformanceReport?.performanceReportByDesk?.map(
                  (user) => user?.number_of_conversions
                )}
                horizontal={false}
              />
            )}
          </Card>
        </div>
      </div>

      {lastContractsByDay && (
        <div className="d-flex">
          <Card className="col-12 p-0">
            <Card.Header>
              <div className="card-title col-12 ">
                <div className="d-flex align-iterms-center">
                  <div className="text-start col-6">
                    Jours écoulés depuis le dernier contrat
                  </div>
                </div>
              </div>
            </Card.Header>
            <GraphLastContractsByDay
              height={"400px"}
              data={lastContractsByDay}
            />
          </Card>
        </div>
      )}
      {/* <div className="border rounded mb-3">
        <h4 className="m-auto col-12 text-center">Sondage</h4>
        <div className="d-flex justify-content-center mt-3">
          <button className="btn-classique col me-2">style classique</button>
          <button className="btn-pes col">style PES si t'as la ref.</button>
        </div>
      </div> */}
      <div className="d-flex">
        {averageRetap && (
          <div className="d-flex col p-0">
            <Card className="col-12 p-0">
              <Card.Header>
                <div className="card-title col-12">
                  <div className="d-flex align-items-center">
                    <div className="text-start col-6">
                      Moyenne en jours de retap par agent
                      <span
                        title="La requête calcule la moyenne de nombre de jours entre chaque encaissement pour ses clients"
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-info-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <GraphAverageRetap height={"400px"} data={averageRetap} />
            </Card>
          </div>
        )}

        {/* {averageRetap && (
          <div className="d-flex col-4 p-0">
            <Card className="col-12 p-0">
              <Card.Header>
                <div className="card-title col-12">
                  <div className="d-flex align-items-center">
                    <div className="text-start">
                      Moyenne en jour de retap par agent
                      <span
                        title="La requête calcule la moyenne de nombre de jours entre chaque encaissement pour ses clients. Si 0, les encaissements se sont faites le même jour."
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      >
                        ?
                      </span>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <GraphPesAverageRetap height={"400px"} data={averageRetap} />
            </Card>
          </div>
        )} */}
      </div>
    </>
  );
};

export default CardsSeller;
