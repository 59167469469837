import Svgdown from "../../../images/arrow-down-svgrepo-com.svg"
import Svgup from "../../../images/arrow-up-svgrepo-com.svg"
import Svg from "../../../images/filter-svgrepo-com.svg"


function arrowSort(NumberPositionArrow) {
  // console.log("NumberPositionArrow", NumberPositionArrow);

  let response;

  switch (NumberPositionArrow) {
    case 1:
      response = (
        <img src={Svgdown}></img>
      );
      break;
    case 2:
      response = (
        <img src={Svgup}></img>
      );
      break;
    case 3:
      response = (<img src={Svg}></img>);
      break;

    default:
      response = "";
  }

  return response;
}

export default arrowSort;
