import React, { useState } from "react";
import { Form, Button, Table, Modal } from "react-bootstrap";

const CallsLead = () => {
  const dataToShow = {
    CDR_ID: "24315809",
    "Created Date": "December 11th 2024, 4:46:47 pm",
    Agent: "clara",
    Type: "Support",
    "WebPhone Status": "ANSWERED",
    Duration: "00:00:29",
    "VoIP URL": "c1.pbx.commpeak.com",
    Recording: "Get Recording",
    "WebRTC Extention": "21168",
  };

  const [formData, setFormData] = useState(dataToShow);
  const [leadToSend, setLeadToSend] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLeadToSend(formData);
    setShowModal(false);
  };

  const handleRowClick = () => {
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="col-12">
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Table striped hover className="p-0 mb-4">
          <thead>
            <tr>
              {Object.keys(dataToShow).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr onClick={handleRowClick} style={{ cursor: "pointer" }}>
              {Object.values(dataToShow).map((value, index) => (
                <td key={index}>{value}</td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier les Détails de l'Appel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form onSubmit={handleSubmit} className="grid-container text-black">
              {Object.keys(formData).map((key) => (
                <div className="grid-item p-0 me-1" key={key}>
                  <div className="mx-2 mb-1">{key}</div>
                  <input
                    type="text"
                    className="form-control"
                    value={formData[key]}
                    onChange={(e) => handleChange(key, e.target.value)}
                    readOnly={key === "CDR_ID"}
                  />
                </div>
              ))}
              <div className="grid-item p-0 me-1 full-width mt-2">
                <Button
                  type="submit"
                  className="border pointer btn btn-green text-black me-2 col-12"
                >
                  Valider
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {leadToSend && (
        <div className="mt-4">
          <h6>Objet à envoyer:</h6>
          <pre>{JSON.stringify(leadToSend, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default CallsLead;
