import React from 'react';
import ReactApexChart from 'react-apexcharts';

const RadarPes = ({ personneSel, personneHover }) => {

  console.log(personneSel)
  console.log(personneHover)

  const series = [];

  if (personneSel) {
    series.push({
      name: personneSel.name,
      data: [
        Math.round(Number(personneSel.percentage_of_max_conversion) || 20),
        Math.round((Number(personneSel.noteContracts) * 100 / 20) || 20),
        Math.round((Number(personneSel.mote_retap) * 100 / 15) || 20),
        Math.round(Number(personneSel.percentage_of_max_total) || 20),
        Math.round(Number(personneSel.percentage_of_max_average) || 20),
        Math.round(Number(personneSel.percentage_of_max_encash) || 20),
      ],
    });
  }
  
  if (personneHover) {
    series.push({
      name: personneHover.name,
      data: [
        Math.round(Number(personneHover.percentage_of_max_conversion) || 20),
        Math.round((Number(personneHover.noteContracts) * 100 / 20) || 20),
        Math.round((Number(personneHover.mote_retap) * 100 / 15) || 20),
        Math.round(Number(personneHover.percentage_of_max_total) || 20),
        Math.round(Number(personneHover.percentage_of_max_average) || 20),
        Math.round(Number(personneHover.percentage_of_max_encash) || 20),
      ],
    });
  }

  const options = {
    chart: {
      type: 'radar',
      height: 350,
      foreColor: '#ffffff', // Couleur des textes du radar
      background: 'transparent', // Fond transparent
    },
    xaxis: {
      categories: ['Conversion', 'Nb contrats', 'Retap j', 'Encaissement', 'Moyenne encaissement', 'Encaissement le plus haut'],
      labels: {
        style: {
          colors: ['#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff'], // Couleur des titres
        },
      },
    },
    yaxis: {
      show: false, // Masquer l'échelle de 0 à 100
    },
    dataLabels: {
      enabled: true, // Afficher les valeurs sur les courbes
    },
    stroke: {
      width: 1,
    },
    markers: {
      size: 0, // Supprimer les points (marqueurs)
    },
    legend: {
      show: false, // Masquer la légende
    },
    grid: {
      show: false, // Masquer les lignes de la grille
    },
    fill: {
      opacity: [0.8, 0.3], // Opacités différentes pour chaque série
    },
  };

  return (
    <div id="chart" className='col-12'>
      <ReactApexChart options={options} series={series} type="radar" height={350} />
    </div>
  );
};

export default RadarPes;