import React, { useState } from "react";
import { Form, Button, Table, Modal } from "react-bootstrap";

const ReassignLogsLead = () => {
  const dataToShow = [
    {
      Agent: "Genettaz Cecile",
      Status: "open",
      ReassingStatus: "open",
      ReassignBy: "jules",
    },
  ];

  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [leadToSend, setLeadToSend] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedData(rowData);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleChange = (key, value) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLeadToSend(selectedData);
    setShowModal(false);
  };

  return (
    <div className="col-12">
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
      <Table striped hover className="p-0 mb-4">
        <thead>
          <tr>
            {Object.keys(dataToShow[0]).map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataToShow.map((item, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(item)}
              style={{ cursor: "pointer" }}
            >
              {Object.values(item).map((value, i) => (
                <td key={i}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
</div>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier les Logs de Réassignation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedData && (
            <div>
              <Form onSubmit={handleSubmit} className="grid-container text-black">
                {Object.keys(selectedData).map((key) => (
                  <div className="grid-item p-0 me-1" key={key}>
                    <div className="mx-2 mb-1">{key}</div>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedData[key]}
                      onChange={(e) => handleChange(key, e.target.value)}
                      readOnly={key === "Agent"}
                    />
                  </div>
                ))}
                <div className="grid-item p-0 me-1 full-width mt-2">
                  <Button
                    type="submit"
                    className="border pointer btn btn-green text-black me-2 col-12"
                  >
                    Valider
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {leadToSend && (
        <div className="mt-4">
          <h6>Objet à envoyer:</h6>
          <pre>{JSON.stringify(leadToSend, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default ReassignLogsLead;