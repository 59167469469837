import React, { useMemo } from "react";
import cardfifaX from "../../../images/badgefifa.png";
// import Mutliplex from "../../../son/Multiplex-Canal.mp3";
import formatNumber from "../../../utils/formatNumber";

export default function RankDeskDay({ data = [], animAscenceur }) {
  // Precompute person data to simplify rendering logic
  const processedData = useMemo(() => {
    return data.map((person, index) => {
      const prevIndex = data.findIndex((p) => p.id === person.id);
      const yPosition = index * 50;
      const isMoving = prevIndex !== -1 && prevIndex !== index;
      return {
        ...person,
        index,
        yPosition,
        isMoving,
      };
    });
  }, [animAscenceur]);

  return (
    <div className="container mt-5">
      <div
        style={{ backgroundColor: "#0c1a31" }}
        className="d-flex flex-column align-items-center pb-3"
      >
        <div className="d-flex col-12">
          <div className="col-1"></div>
          <div
            style={{ color: "#d2bb81" }}
            className="fs-22 fw-bold col-10 text-center"
          >
            GOAT CUP
          </div>
          <div className="circle-fifa mt-2 ms-0"></div>
        </div>
        <div className="fs-14 text-white text-center">
          CLASSEMENT JOURNALIER CHIFFRE/DESK
        </div>
      </div>
      <div style={{ position: "relative", height: `${data.length * 50}px` }}>
        {processedData.map(({ id, avatar, desk, rankMonthly, total, index, yPosition, isMoving }) => (
          <div
            key={id}
            className="person-item d-flex align-items-center"
            style={{
              transform: `translateY(${yPosition}px)`,
              backgroundColor: "rgba(12, 26, 49, 0.4)",
              zIndex: isMoving && animAscenceur ? 10000 : 0,
              color: "white",
            }}
          >
            <div
              className="col-1 p-0 d-flex align-items-center justify-content-center h-100 fw-bold fs-20"
              style={{
                backgroundColor: index % 2 === 0 ? "#d2bb81" : "#a78e60",
              }}
            >
              {index + 1}
            </div>
            <div className="col-2 p-0">
              <div
                className="d-flex align-items-center position-relative"
                style={{ height: "60px", width: "50px" }}
              >
                <div
                  className="position-absolute"
                  style={{
                    height: "60px",
                    width: "40px",
                    backgroundImage: `url(${cardfifaX})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    marginLeft: "5px",
                  }}
                />
                <img
                  src={avatar}
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                    position: "relative",
                    zIndex: 1,
                  }}
                  className="m-auto"
                />
              </div>
            </div>
            <div
              className="d-flex col-4 align-items-center text-uppercase fs-14 fw-bold ps-0"
              style={{ height: "100%" }}
            >
              <div> DESK {desk}</div>
            </div>
            <div
              className="col-1 pointer"
              title="Point pour le classement mensuel. Les trois premiers à la fin de chaque journée reçoivent respectivement 3, 2 et 1 points."
            >
              {/* {rankMonthly} */}
            </div>
            <div
              className="col-4 fw-bold fs-14 text-end pe-1 pointer"
              style={{ color: "#d2bb81" }}
              title="Total des signés de la journée"
            >
              {formatNumber(total)} €
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
