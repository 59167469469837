"use client";

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  setGoalAnimation,
  getGoalAnimation,
} from "../../Redux/slices/actions.slice";
import { useDispatch, useSelector } from "react-redux";
import fondGoalAnimation2 from "../../../images/packsbg19.jpg";
import cardfifa from "../../../images/badgefifa.png";
import CountUp from "react-countup";
import ConfettiExplosion from "react-confetti-explosion"; // Importation de ConfettiExplosion

export default function GoalAnimation({ differences }) {
  const [animate, setAnimate] = useState(false);
  const [explode, setExplode] = useState(false); // État pour l'explosion de confettis
  console.log(differences[0]);
  const showGoalAnimation = useSelector(getGoalAnimation);

  const initialPeople = [
    {
      id: 1,
      desk: 1,
      avatar:
        "https://imgairforcecrm.fra1.digitaloceanspaces.com/avatars/avatars m/vince.png",
      total: 0,
    },
    {
      id: 2,
      desk: 2,
      avatar:
        "https://imgairforcecrm.fra1.digitaloceanspaces.com/avatars/avatars m/antoine.png",
      total: 0,
    },
    {
      id: 3,
      desk: 3,
      avatar:
        "https://imgairforcecrm.fra1.digitaloceanspaces.com/avatars/avatars m/eric.png",
      total: 0,
    },
    {
      id: 4,
      desk: 4,
      avatar:
        "https://imgairforcecrm.fra1.digitaloceanspaces.com/avatars/avatars m/jerome.png",
      total: 0,
    },
    {
      id: 5,
      desk: 5,
      avatar:
        "https://imgairforcecrm.fra1.digitaloceanspaces.com/avatars/avatars m/pastori.png",
      total: 0,
    },
    {
      id: 6,
      desk: 6,
      avatar:
        "https://imgairforcecrm.fra1.digitaloceanspaces.com/avatars/avatars m/john.png",
      total: 0,
    },
    {
      id: 7,
      desk: 7,
      avatar:
        "https://imgairforcecrm.fra1.digitaloceanspaces.com/avatars/avatars m/clement.png",
      total: 0,
    },
    {
      id: 8,
      desk: 8,
      avatar:
        "https://imgairforcecrm.fra1.digitaloceanspaces.com/avatars/avatars m/lashke.png",
      total: 0,
    },
    {
      id: 9,
      desk: 9,
      avatar:
        "https://imgairforcecrm.fra1.digitaloceanspaces.com/avatars/avatars m/stephane.png",
      total: 0,
    },
  ];

  const getOverlayColor = (desk) => {
    switch (desk) {
      case 1:
        return "rgba(0, 0, 255, 0.1)"; // Bleu léger
      case 2:
        return "rgba(0, 255, 0, 0.1)"; // Vert léger
      case 3:
        return "rgba(0, 0, 0, 0.1)"; // Rouge léger
      case 4:
        return "rgba(255, 255, 0, 0.1)"; // Jaune léger
      case 5:
        return "rgba(0, 255, 255, 0.1)"; // Cyan léger
      case 6:
        return "rgba(255, 0, 255, 0.1)"; // Magenta léger
      case 7:
        return "rgba(128, 0, 128, 0.1)"; // Violet léger
      case 8:
        return "rgba(255, 165, 0, 0.1)"; // Orange léger
      case 9:
        return "rgba(0, 128, 128, 0.1)"; // Turquoise léger
      default:
        return "rgba(128, 128, 128, 0.1)"; // Gris léger par défaut
    }
  };

  const getBorderColor = (desk) => {
    switch (desk) {
      case 1:
        return "rgb(0, 0, 255)"; // Bleu
      case 2:
        return "rgb(0, 255, 0)"; // Vert
      case 3:
        return "#00e5e5"; // Rouge
      case 4:
        return "rgb(255, 255, 0)"; // Jaune
      case 5:
        return "rgb(0, 255, 255)"; // Cyan
      case 6:
        return "rgb(255, 0, 255)"; // Magenta
      case 7:
        return "rgb(128, 0, 128)"; // Violet
      case 8:
        return "rgb(255, 165, 0)"; // Orange
      case 9:
        return "rgb(0, 128, 128)"; // Turquoise
      default:
        return "rgb(128, 128, 128)"; // Gris par défaut
    }
  };

  // Trouver la personne correspondant au desk
  const person = initialPeople.find(
    (p) => p.desk || p.name === differences[0].desk || differences[0].name
  ) || {
    avatar: "https://via.placeholder.com/150", // Avatar par défaut si non trouvé
    desk: 99,
  };

  useEffect(() => {
    if (differences.length > 0) {
      // setExplode(true);
      const timer = setTimeout(() => {
        setExplode(true);
      }, 17000); // Durée de l'explosion en millisecondes
      return () => clearTimeout(timer);
    }
  }, [differences]);

  return (
    <>
      <div
        className="goal-container d-flex align-items-center justify-content-center"
        style={{
          position: "relative",
          width: "90vw",
          height: "100vh",
          backgroundColor: "rgba(128, 128, 128, 0.5)",
          backgroundImage: `url(${fondGoalAnimation2})`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "80%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {explode && (
            <ConfettiExplosion
              force={0.9}
              duration={3000}
              particleCount={250}
              width={1400}
              // force={((differences[0].total.new - differences[0].total.old) * 1) / 2000000}
              // duration={((differences[0].total.new - differences[0].total.old) * 3000) / 2000000}
              // particleCount={((differences[0].total.new - differences[0].total.old) * 400) / 2000000}
              // width={((differences[0].total.new - differences[0].total.old) * 1600) / 2000000}
            />
          )}
        </div>
        <div className="col-12 m-auto h-75 d-flex">
          <div
            style={{ position: "relative", height: "80%", marginLeft: "120px" }}
            className="col-3"
          >
            <img
              src={cardfifa}
              alt="Goal Avatar"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                position: "absolute",
                zIndex: 1,
              }}
            />
            <div
              className="d-flex align-items-center justify-content-center ms-3"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 2,
              }}
            >
              <img
                src={differences[0].avatar}
                alt="Goal Avatar"
                style={{
                  width: "50%",
                  height: "50%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center h-75 col-8">
            <div
              style={{ color: "white", fontSize: "10rem", fontWeight: "bold" }}
            >
              {/* {`DESK ${differences[0].desk}`} */}
              {differences[0].pseudo}
            </div>
            <div
              style={{ color: "white", fontSize: "7rem", fontWeight: "bold" }}
            >
              +{" "}
              <CountUp
                start={0}
                end={
                  differences &&
                  (differences[0].total?.new ||
                    differences[0].total_amount_day?.new) -
                    (differences[0].total?.old ||
                      differences[0].total_amount_day?.old)
                }
                duration={21}
                separator=" "
              />{" "}
              €
            </div>
          </div>
        </div>
        <div
          className="goal-overlay d-flex align-items-center justify-content-center"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            // backgroundColor: getOverlayColor(person.desk),
            zIndex: 3,
          }}
        >
          <div className="goal-container ps-5">
            <div
              className={`goal-text animate-goal`}
              style={{
                color: "#00e5e5",
                textShadow: `-3px -3px 0 ${getBorderColor(
                  person.desk
                )}, 3px -3px 0 ${getBorderColor(
                  person.desk
                )}, -3px 3px 0 ${getBorderColor(
                  person.desk
                )}, 3px 3px 0 ${getBorderColor(person.desk)}`,
              }}
            >
              <span>G</span>
              <span>O</span>
              <span>A</span>
              <span>L</span>
              <span>!</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
